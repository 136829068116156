import React from 'react'
import PropTypes from 'prop-types'
import Component from './component'
import { UrlBuilder } from 'Utils'
import config from 'Config'

const ExtendedStoreFilter = props => {
  const { queryString } = props
  const { storesBlacklist } = config

  const getSelectedCount = () => {
    const builder = new UrlBuilder(queryString)
    return builder.getStores().length
  }

  const getClearAllStoresLink = () => {
    const builder = new UrlBuilder(queryString)
    return builder.removeAllStores().getQueryString()
  }

  const stores = props =>
    props.data.products.aggregations.stores
      .filter(store => !storesBlacklist.includes(store.id))
      .map(store => {
        const isSelected = !!queryString.match(
          new RegExp(`\\bstore=${store.id}\\b`)
        )
        const builder = new UrlBuilder(queryString)
        const to = isSelected
          ? builder.removeStore(store.id).setPage(1).getQueryString()
          : builder.addStore(store.id).setPage(1).getQueryString()

        return {
          id: store.id,
          label: store.name,
          productsCount: store.count,
          isSelected,
          to
        }
      })

  return (
    <Component
      className="store-filter"
      selectedItemsCount={getSelectedCount()}
      clearStoresLink={getClearAllStoresLink()}
      items={stores(props)}
      queryString={queryString}
      withTitle={props.withTitle}
    />
  )
}

ExtendedStoreFilter.propTypes = {
  data: PropTypes.shape({
    products: PropTypes.shape({
      aggregations: PropTypes.shape({
        stores: PropTypes.array
      })
    })
  }),
  queryString: PropTypes.string,
  withTitle: PropTypes.any
}

export default ExtendedStoreFilter
