import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Component from './component'
import { UrlBuilder } from 'Utils'

const mapStateToProps = state => {
  return {
    screenType: state.UI.screenType || ''
  }
}

const SaleFilterContainer = props => {
  const builder = new UrlBuilder(props.queryString)
  const isSelected = !!builder.getIsOnSale()
  const to = isSelected
    ? builder.removeIsOnSale().setPage(1).getQueryString()
    : builder.addIsOnSale().setPage(1).getQueryString()

  return (
    <Component
      isDisabled={props.isDisabled}
      isSelected={isSelected}
      to={to}
      {...props}
    />
  )
}

SaleFilterContainer.propTypes = {
  isDisabled: PropTypes.bool,
  queryString: PropTypes.string
}

export default connect(mapStateToProps, null)(SaleFilterContainer)
