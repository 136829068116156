import { css } from '@emotion/core'
import theme from 'Theme'
const { typography, colors } = theme

export default props => {
  let breakPoint = 1100
  let width = ''
  if (typeof window.innerWidth !== 'undefined') {
    width = window.innerWidth
  }
  const slicePosition =
    width < breakPoint ? props.levels.length : props.levels.length - 1
  return css`
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .category-panel {
      display: block;
      width: 33.3%;
      min-height: 410px;
      position: relative;
      padding: 0 8px 32px 8px;
      z-index: 1;

      @media screen and (min-width: 1100px) {
        width: 25%;
      }

      &:last-of-type {
        padding-right: 16px;
      }

      &:nth-of-type(${`-n+${slicePosition}`}) {
        display: none;
      }

      &:hover {
        .category-panel__img {
          &::after {
            opacity: 1;
          }
        }
      }
    }

    .category-panel__img {
      height: 360px;
      width: 100%;
      margin-bottom: 10px;
      position: relative;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: ${colors.imageTint};
        opacity: 0;
      }
    }

    .category-panel__title {
      width: 100%;
      text-align: center;
      text-transform: capitalize;
      font-size: ${typography.baseFontSize};
    }
  `
}
