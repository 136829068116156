import gaSettings from './gaSettings'

const getSubMenuSettings = (() => {
  return gaSettings.productList.subCategoryMenu
})()

const getProductCardSettings = (() => {
  return gaSettings.productList.ProductCard
})()

const getSaleFilterSettings = (() => {
  return gaSettings.productList.filters.SaleFilter
})()

const getStoreFilterSettings = (() => {
  return gaSettings.productList.filters.StoreListFilter
})()

const getPriceFilterSettings = (() => {
  return gaSettings.productList.filters.PriceFilter
})()

const getColorFilterSettings = (() => {
  return gaSettings.productList.filters.ColorListFilter
})()

const getCompareBtnSettings = (() => {
  return gaSettings.productPage.productCompare.compareButton
})()

const getBuyBtnSettings = (() => {
  return gaSettings.productPage.mainProduct.buyButton
})()

const getFavouriteBtnSettings = (() => {
  return gaSettings.productPage.mainProduct.favouriteButton
})()

const getBreadcrumbsSettings = (() => {
  return gaSettings.breadcrumbs
})()

const gaSelectors = {
  getSubMenuSettings,
  getProductCardSettings,
  getSaleFilterSettings,
  getStoreFilterSettings,
  getPriceFilterSettings,
  getCompareBtnSettings,
  getBuyBtnSettings,
  getFavouriteBtnSettings,
  getBreadcrumbsSettings,
  getColorFilterSettings
}

export default gaSelectors
