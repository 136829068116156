const activeColor = '#000'
export const colors = {
  bodyBackground: '#FFF',
  body: '#333',
  active: activeColor,
  primary: '#333',
  secondary: '#979797',
  tertiary: '#FFF',
  discount: activeColor,
  discountedPrice: '#787878',
  alt1: activeColor,
  alt2: '#891954',
  text2: '#787878',
  text3: activeColor,
  text4: '#ccc',
  borderGrey: '#D8D8D8',
  bg2: '#999',
  oos: '#C40820',
  bg3: '#BEBEBE',
  bg4: '#f5f5f5',
  complementary: {
    primary: '#FFF',
    secondary: '#666',
    alt1: '#999',
    tertiary: '#333'
  },
  toolbar: {
    buttonColor: '#CCC',
    textColor: '#FFF',
    backgroundColor: activeColor,
    backgroundColorDark: '#891954'
  },
  mainNav: {
    active: 'black'
  }
}

export default colors
