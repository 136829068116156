import React from 'react'
import { hot } from 'react-hot-loader'
import theme from './theme'
import { ThemeProvider } from 'emotion-theming'
import Page from './components/Page'
import Routes from 'Routes'

const App = () => (
  <ThemeProvider theme={theme}>
    <Page>
      <Routes />
    </Page>
  </ThemeProvider>
)

export default hot(module)(App)
