import React from 'react'
import PropTypes from 'prop-types'

const Title = ({
  /* DATA */
  children,
  /* FEATURES */
  color = '',
  backGroundColor = '',
  hasUnderLineAfter,
  hasDownArrow,
  shouldRender = true,
  onClick = () => {},
  className = '',
  style = {}
}) => {
  return shouldRender ? (
    <div {...{ className, style, onClick }}>
      <h3>{children}</h3>
    </div>
  ) : null
}

Title.displayName = 'Title'

Title.propTypes = {
  backGroundColor: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.string,
  hasDownArrow: PropTypes.any,
  hasUnderLineAfter: PropTypes.any,
  onClick: PropTypes.func,
  shouldRender: PropTypes.bool,
  style: PropTypes.object
}

export default Title
