import get from 'lodash/get'

const createGAArguments = (product, layoutIndex) => {
  const storeName = get(product.store, 'name', '')
  return {
    productUuid: product.id,
    position: layoutIndex,
    value: product.prices.raw,
    productStore: storeName,
    isStocked: product.flags.isStocked,
    isDiscounted: product.flags.isDiscounted,
    currencyCode: product.prices.currency.symbol,
  }
}

const createGALabel = (product, layoutIndex) => {
  const storeName = get(product.store, 'name', '')
  return [
    product.id,
    `store=${storeName}`,
    `productPosition=${layoutIndex}`,
    `isStocked=${product.flags.isStocked}`,
    `isDiscounted=${product.flags.isDiscounted}`,
    `price=${product.prices.raw}`
  ]
}

const createArticleProductCardGAArgs = ({
  articleUuid = '',
  productUuid = '',
  position = '',
  destinationUrl = ''
}) => ({
  articleUuid,
  productUuid,
  position,
  destinationUrl
})

const artilceProductCardGALabel = ({
  articleUuid = '',
  productUuid = '',
  position = '',
  destinationUrl = ''
}) => {
  return [
    `articleUuid=${articleUuid}`,
    `productUuid=${productUuid}`,
    `position=${position}`,
    `destinationUrl=${destinationUrl}`
  ]
}

const createContentCardGAProps = ({ uuid = '', type = '', position = '' }) => ({
  type, uuid, position
})

const contentCardGALabel = ({ uuid = '', type = '', position = '' }) => {
  return [`type=${type}`, `uuid=${uuid}`, `position=${position}`]
}

export {
  createGAArguments,
  createGALabel,
  createArticleProductCardGAArgs,
  artilceProductCardGALabel,
  createContentCardGAProps,
  contentCardGALabel,
}
