import { css } from '@emotion/core'
import { smallOnly, mediumUp } from 'Utils'
import theme from 'Theme'
const { colors, mixins } = theme

export default () => css`
  a,
  button {
    display: block;
    appearance: none;
    line-height: 16px;
    font-size: 16px;
    cursor: pointer;
    padding: 0 8px;

    &.selected {
      background-color: ${colors.primary};
      color: ${colors.complementary.primary};
    }
  }

  @media ${smallOnly} {
    margin-left: 0;
    width: 100%;

    .links {
      > a {
        ${mixins.filterPanelListItem(theme)};
        border-bottom: 1px solid ${colors.mobileBorderLine};
        justify-content: space-between;
      }
    }
  }

  @media ${mediumUp} {
    a,
    button {
      ${mixins.filterLinks(theme)}
      appearance: none;
      cursor: pointer;

      &.selected {
        background-color: ${colors.primary};
        color: ${colors.complementary.primary};
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .links {
      max-height: 300px;
      overflow: auto;
    }

    .close-icon {
      ${mixins.closeIcon(theme)}
    }
  }
`
