import { css } from '@emotion/core'
import theme from 'Theme'
import { smallOnly, mediumUp } from 'Utils'
const {
  colors,
  spacing,
  mixins,
  typography,
  components: { storeListFilter }
} = theme

export const plain = () => css`
  position: relative;

  .search-store__wrapper {
    position: relative;
    @media ${smallOnly} {
      background-color: ${colors.cardGrey};
    }

    @media ${mediumUp} {
      border: 1px solid ${colors.mobileBorderLine};
    }

    .search-store__input {
      display: inline-block;
      padding: 10px 50px 10px 10px;
      font-size: ${typography.baseFontSize};
      width: 100%;
      height: 40px;
      border: none;
      background-color: transparent;
      outline: none;

      @media ${mediumUp} {
        width: calc(100% - 4px);
        &:focus {
          outline: none;
        }
      }
    }

    .search-icon {
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .list-string {
    font-weight: bold;
  }

  .title {
    padding-bottom: 0.6rem;
    margin-left: 05px;
    text-transform: uppercase;
    font-weight: 600;
    color: ${storeListFilter.title.color};
  }

  .options {
    font-family: ${storeListFilter.font.family};
    display: flex;
    flex: 1;
  }

  .clear-label {
    flex: 1;
    text-align: right;
    margin-right: 8px;
    text-transform: lowercase;
    a,
    button {
      color: ${storeListFilter.clear.color};
      text-decoration: none;
      border: none;
      background: none;
      text-transform: lowercase;
      font-size: 12px;

      &:hover {
        color: ${storeListFilter.clear.hover.color};
      }
    }
  }

  .selected-items {
    flex: 1;
    margin-left: 05px;
    font-size: 12px;
  }

  .list {
    max-height: 340px;
    list-style-type: none;
    list-style: none;
    overflow: auto;

    ${
      '' /* &::-webkit-scrollbar {
      width: ${storeListFilter.scrollbar.width};
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      background: ${storeListFilter.scrollbar.thumb.color};
    }

    &::-webkit-scrollbar-track {
      background: ${storeListFilter.scrollbar.track.color};
    } */
    }

    @media ${smallOnly} {
      padding: 6px 12px;
      margin-top: 0.2rem;
    }

    @media ${mediumUp} {
      margin-top: 16px;
      margin-right: 1px;
      height: 270px;
    }
  }

  @media ${smallOnly} {
    .list-item {
      ${mixins.filterPanelListItem(theme)};
      border-bottom: 1px solid ${colors.mobileBorderLine};
      padding-left: ${spacing.vertical.xsmall};
      span {
        display: block;
        width: 100%;
      }
      a {
        display: block;
      }
      a {
        padding-bottom: 5px;
        padding-top: 5px;
      }
      font-family: ${storeListFilter.font.family};
      cursor: pointer;
      &:hover {
        color: ${storeListFilter.title.hoverColor};
      }
      .list-letter {
        font-weight: bold;
      }
      &:last-of-type {
        margin-bottom: 50px;
      }
    }

    .selected {
      background-color: black;
      color: white;
      & > a {
        color: white;
      }
    }
  }

  @media ${mediumUp} {
    .list-item {
      a {
        ${mixins.filterLinks(theme)}
      }
    }

    .selected {
      a {
        background-color: black;
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .close-icon {
      ${mixins.closeIcon(theme)}
    }

    .list-letter {
      font-weight: bold;
      display: block;
      line-height: 16px;
      padding: 8px;
    }
  }
`

export default plain
