import { generateTopCategories } from 'Utils'
import config from 'Config'
import { ACTION_TYPES as types } from 'Constants'

export const hmrAction = () => ({
  type: types.HMR
})

export const journeyTrack = props => ({
  type: types.JOURNEY_TRACK,
  payload: {
    routeName: props.location.pathname.match(
      new RegExp(`${config.shopPath}/(\\w+)`)
    )[1],
    params: props.params,
    extra: {
      topCategories: generateTopCategories(props),
      subCategories: props.categories
    },
    name: props.category.name,
    to: props.location.pathname
  }
})

export const logCurrentPage = payload => ({
  type: types.LOG_CURRENT_PAGE,
  payload
})

export const storeProductsResponse = payload => ({
  type: types.STORE_PRODUCTS_RESPONSE,
  payload
})

export const storeLocation = payload => ({
  type: types.STORE_LOCATION,
  payload
})

export const toggleLoading = payload => ({
  type: types.TOGGLE_LOADING
})

export const trackLink = payload => ({
  type: types.TRACK_LINK,
  payload
})

export const trackLinkDecrease = () => ({
  type: types.TRACK_LINK_DECREASE
})

export const updateScreenType = payload => ({
  type: types.UPDATE_SCREEN_WIDTH,
  payload
})

export const toggleFlyOutPanel = () => ({
  type: types.TOGGLE_FLY_OUT_PANEL
})

export const toggleNavPanel = payload => ({
  type: types.TOGGLE_NAV_PANEL,
  payload
})

export const openNavPanel = payload => ({
  type: types.OPEN_NAV_PANEL,
  payload
})

export const closeNavPanel = payload => ({
  type: types.CLOSE_NAV_PANEL,
  payload
})

export const setActiveTopLevelCategory = payload => ({
  type: types.SET_ACTIVE_TOP_CATEGORY,
  payload
})

export const setCurrentPath = payload => ({
  type: types.SET_CURRENT_PATH,
  payload
})

export const setCurrentCategory = payload => ({
  type: types.SET_CURRENT_CATEGORY,
  payload
})

export const setActiveContentType = payload => ({
  type: types.SET_ACTIVE_CONTENT_TYPE,
  payload
})

export const pushPriceFilters = payload => ({
  type: types.PUSH_PRICE_FILTERS,
  payload
})

export const setPriceFilters = payload => ({
  type: types.SET_PRICE_FILTERS,
  payload
})

export const setHomeTabMode = payload => ({
  type: types.SET_TAB_MODE,
  payload
})

export const setSerachHint = payload => ({
  type: types.SET_SEARCH_HINTS,
  payload
})

export const storeCategoryTree = payload => ({
  type: types.STORE_CATEGORY_TREE,
  payload
})

export const removeFavourite = payload => ({
  type: types.REMOVE_FROM_FAVOURITES,
  payload
})

export const addFavourite = payload => ({
  type: types.ADD_TO_FAVOURITES,
  payload
})

export const addFavouritesFromLocalStorage = payload => ({
  type: types.FAVOURITES_FROM_LOCALSTORAGE,
  payload
})