import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Component from './component'
import { skin } from 'Utils'
import skins from './skins'
import { closeNavPanel } from 'Actions'

const mapStateToProps = state => {
  return {
    screenType: state.UI.screenType || ''
  }
}

const mapDispatchToProps = dispatch => ({
  closeNavPanel: bindActionCreators(closeNavPanel, dispatch)
})

export default skin(
  connect(mapStateToProps, mapDispatchToProps)(Component),
  skins
)
