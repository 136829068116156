import React from 'react'
import { Route, Switch } from 'react-router-dom'
import NoMatch from './components/NoMatch'
import { ScrollToTop } from 'Utils'
import config from 'Config'
import * as Pages from './asyncRoutes'

const { routes } = config

const renderRoute = (route, index) => {
  const PageComponent = Pages[route.page]

  return route.page ? (
    <Route
      key={`route-${index}`}
      {...route}
      render={({ match, location, staticContext }) => (
        <PageComponent
          match={match}
          staticContext={staticContext}
          location={location}
        />
      )}
    />
  ) : null
}

const Routes = props => {
  return (
    <ScrollToTop>
      <Switch>
        {Object.keys(routes).map((route, index) => renderRoute(routes[route]))}
        <Route render={props => <NoMatch {...props} />} />
      </Switch>
    </ScrollToTop>
  )
}

export default Routes
