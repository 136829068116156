import React from 'react'
import PropTypes from 'prop-types'

const CustomSelect = ({
  currentValue,
  onChange = () => {},
  children = null,
  className = '',
  style = {}
}) => {
  return (
    <div {...{ className, style }}>
      <select
        className="dropdown"
        value={currentValue}
        onChange={e => onChange(e)}
      >
        {children}
      </select>
      <span className="arrow" />
    </div>
  )
}

CustomSelect.displayName = 'CustomSelect'

CustomSelect.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  currentValue: PropTypes.any,
  onChange: PropTypes.func,
  style: PropTypes.object
}

export default CustomSelect
