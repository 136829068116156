import { colors } from '../colors'
import { typography } from '../typography'

export default {
  text: {
    color: colors.complementary.primary,
    fontFamily: typography.headings.fontFamily,
    background: {
      color: colors.primary
    }
  }
}
