import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { skin, GAEvent } from 'Utils'
import { Link, Icon } from 'Blocks'
import skins from './skins'
import { gaSelectors } from 'Config'
import groupBy from 'lodash/groupBy'
import config from 'Config'

const StoreComponent = ({ id, label, to, isSelected }, state) => {
  return (
    <li key={id} className={`list-item ${isSelected ? 'selected' : ''}`}>
      {!isSelected ? (
        <GAEvent
          category={gaSelectors.getStoreFilterSettings.category}
          action={gaSelectors.getStoreFilterSettings.action}
          label={`storeName=${label}`}
          additionalProps={{
            filterStoreName: label,
          }}
        >
          <Link {...{ to }} key={id}>
            {state.refine ? formatLabel(label, state.query) : label}
          </Link>
        </GAEvent>
      ) : (
        <Link to={to} key={id}>
          {label}
          {isSelected && <Icon name="close" className="close-icon" />}
        </Link>
      )}
    </li>
  )
}

StoreComponent.propTypes = {
  id: PropTypes.any,
  isSelected: PropTypes.any,
  label: PropTypes.any,
  to: PropTypes.any
}

const StoreList = state => {
  const { results } = state
  const noStoreFound = (
    <li className="list-item">
      <span>No store found</span>
    </li>
  )

  const renderKeysVisible = results => {
    const LetterEntry = key => (
      <li key={key} className="list-item">
        <span className="list-letter">{key}</span>
      </li>
    )

    const groupedStores = groupBy(results, item => item.label.charAt(0))
    let flattened = []
    for (let key in groupedStores) {
      flattened.push(key)
      flattened = flattened.concat(groupedStores[key])
    }
    return flattened.map(store =>
      typeof store === 'string'
        ? LetterEntry(store)
        : StoreComponent(store, state)
    )
  }
  const renderOnlyStores = results =>
    results.map(store => StoreComponent(store, state))

  if (results.length && !state.query) {
    return renderKeysVisible(results)
  }
  if (results.length && state.query) {
    return renderOnlyStores(results)
  }
  return noStoreFound
}

StoreList.propTypes = {
  query: PropTypes.any,
  results: PropTypes.array
}

const formatLabel = (label, str) => {
  const replaced = label.replace(
    new RegExp(`(.*)(${str})(.*)`, 'i'),
    '$1---$2---$3'
  )
  const newLabel = replaced.split('---')
  return [
    newLabel[0],
    <span key={`format-label-${label}`} className="list-string">
      {newLabel[1]}
    </span>,
    newLabel[2]
  ]
}

class ExtendedStoreFilter extends Component {
  static propTypes = {
    className: PropTypes.any,
    items: PropTypes.array
  }

  state = {
    query: '',
    results: this.props.items,
    refine: false
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.items !== this.props.items) {
      this.setState({ results: this.props.items })
    }
    if (this.state.query.length !== prevState.query.length) {
      this.state.query.length ? this.filterStoreList() : this.resetStoreList()
    }
  }

  resetStoreList = () => {
    this.setState({ results: this.props.items, refine: false })
  }

  filterStoreList = () => {
    // Filtering on fuzzy search, then sort on first occurence of the match, then the exact matches sort to the top
    const { items } = this.props
    const { query } = this.state
    const fuzzyPattern = new RegExp(
      query.replace(/./g, '$&.{0,3}').replace(/\.\{0,3\}$/, ''),
      'i'
    )
    const filtered = items.filter(item => item.label.match(fuzzyPattern))
    filtered.sort((a, b) => {
      return (
        a.label.split(fuzzyPattern)[0].length -
        b.label.split(fuzzyPattern)[0].length
      )
    })
    filtered.sort((a, b) => {
      const ml = item => item.label.match(new RegExp(query, 'i'))
      if (ml(a) && !ml(b)) {
        return -1
      } else if (!ml(a) && ml(b)) {
        return 1
      } else {
        return 0
      }
    })

    this.setState({ results: filtered, refine: true })
  }

  handleInputChange = () => {
    this.setState({ query: this.search.value })
  }

  render() {
    const { className } = this.props

    return (
      <div {...{ className }}>
        <div className="search-store__wrapper">
          <input
            className="search-store__input"
            placeholder={
              config.filters.extendedStore.placeholder
                ? config.filters.extendedStore.placeholder
                : 'Search store'
            }
            ref={input => {
              this.search = input
            }}
            onChange={this.handleInputChange}
            type="text"
          />
          <span className="search-icon">
            <Icon name="search" />
          </span>
        </div>
        <ul className="list">
          <StoreList {...this.state} />
        </ul>
      </div>
    )
  }
}

export default skin(ExtendedStoreFilter, skins)
