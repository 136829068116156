import panel from './panel'
import panelCard from './panelCard'
import priceFilter from './priceFilter'
import comparePage from './comparePage'
import productCard from './productCard'
import storeListFilter from './storeListFilter'
import filterPanels from './filterPanels'
import title from './title'
import categoryFilter from './categoryFilter'
import menu from './menu'
import breadcrumbs from './breadcrumbs'
import articleItem from './articleItem'
import icon from './icon'
import offCanvas from './offCanvas'
import adminToolbar from './adminToolbar'
import adminPageToolbar from './adminPageToolbar'
import productPrice from './productPrice'
import productOverview from './productOverview'
import dropdown from './dropdown'
import articleTarget from './articleTarget'
import articleProductCard from './articleProductCard'
import articleCard from './articleCard'

export default {
  articleCard,
  articleProductCard,
  articleTarget,
  panel,
  panelCard,
  priceFilter,
  productCard,
  comparePage,
  storeListFilter,
  filterPanels,
  title,
  icon,
  offCanvas,
  categoryFilter,
  menu,
  breadcrumbs,
  articleItem,
  adminToolbar,
  adminPageToolbar,
  productPrice,
  productOverview,
  dropdown
}
