import floor from 'lodash/floor'
import keys from 'lodash/keys'
import includes from 'lodash/includes'
import clone from 'lodash/clone'
import remove from 'lodash/remove'
import qs from 'query-string'

export class UrlBuilder {
  static get SORT_ON_DEFAULT() {
    return 'relevance'
  }

  static get SORT_ON_RELEVANCE() {
    return 'relevance'
  }

  static get SORT_ON_PRICE() {
    return 'price'
  }

  static get SORT_DIRECTION_DEFAULT() {
    return ''
  }

  static get SORT_DIRECTION_ASC() {
    return 'asc'
  }

  static get SORT_DIRECTION_DESC() {
    return 'desc'
  }

  static get FILTER_CATEGORY_DEFAULT() {
    return ''
  }

  constructor(props) {
    this.url = '?'
    if (typeof props === 'string') {
      props = qs.parse(props)
      props.stores = props.store ? [].concat(props.store) : []
      props.xstores = props.xstore ? [].concat(props.xstore) : []
      props.colors = props.color ? [].concat(props.color) : []
    }
    this.filters = {
      theme: props ? props.theme || null : null,
      term: props ? props.term || null : null,
      colors: props ? props.colors || [] : [],
      minPrice: props ? props.minPrice || props.min || null : null,
      maxPrice: props ? props.maxPrice || props.max || null : null,
      isOnSale: props ? props.isOnSale || props.sale || null : null,
      stores: props ? clone(props.stores) || [] : [],
      page: props ? props.page || 1 : 1,
      sortOn: props
        ? props.sortOn || props.sort || UrlBuilder.SORT_ON_DEFAULT
        : UrlBuilder.SORT_ON_DEFAULT,
      sortDirection: props
        ? props.sortDirection ||
          props.order ||
          UrlBuilder.SORT_DIRECTION_DEFAULT
        : UrlBuilder.SORT_DIRECTION_DEFAULT,
      category: props
        ? props.category || UrlBuilder.FILTER_CATEGORY_DEFAULT
        : UrlBuilder.FILTER_CATEGORY_DEFAULT,
      excludeStores: props ? clone(props.xstores) || [] : [],
      dialog: props ? props.dialog || null : null,
      dialogView: props ? props.dialogView || null : null,
      dialogPanel: props ? props.dialogPanel || null : null
    }

    this.appliedFilters = []
  }

  applyPrefix() {
    this.url += this.appliedFilters.length ? '&' : ''
  }

  addMinPrice(price) {
    this.filters.minPrice = floor(price)

    return this
  }

  removeMinPrice() {
    this.filters.minPrice = null

    return this
  }

  addMaxPrice(price) {
    this.filters.maxPrice = floor(price)

    return this
  }

  removeMaxPrice() {
    this.filters.maxPrice = null

    return this
  }

  removeAllPrices() {
    this.filters.maxPrice = null
    this.filters.minPrice = null

    return this
  }

  excludeIsOnsale() {
    this.filters.isOnSale = false

    return this
  }

  addIsOnSale() {
    this.filters.isOnSale = true

    return this
  }

  removeIsOnSale() {
    this.filters.isOnSale = null

    return this
  }

  addStore(store) {
    if (store && !includes(this.filters.stores, store)) {
      this.filters.stores.push(store)
    }

    return this
  }

  addExcludeStore(store) {
    if (store && !includes(this.filters.excludeStores, store)) {
      this.filters.excludeStores.push(store)
    }

    return this
  }

  addColor(color) {
    if (color && !includes(this.filters.colors, color)) {
      this.filters.colors.push(color)
    }

    return this
  }

  removeColor(color) {
    if (color && this.filters.colors.length) {
      remove(this.filters.colors, item => {
        return item === color
      })
    }

    return this
  }

  removeAllColors() {
    this.filters.colors = []
    return this
  }

  applyColors() {
    const colors = this.filters.colors

    if (colors.length) {
      this.applyPrefix()
      this.appliedFilters.push('color')
      this.url += colors.map(color => `color=${color}`).join('&')
    }
  }

  getColors() {
    return this.filters.colors
  }

  getIsOnSale() {
    return this.filters.isOnSale
  }

  getStores() {
    return this.filters.stores
  }

  removeStore(store) {
    if (store && this.filters.stores.length) {
      remove(this.filters.stores, item => {
        return Number.isInteger(store)
          ? item === store.toString()
          : item === store
      })
    }

    return this
  }

  removeExcludeStore(store) {
    if (store && this.filters.excludeStores.length) {
      remove(this.filters.excludeStores, item => {
        return item === store
      })
    }

    return this
  }

  removeAllStores() {
    this.filters.stores = []
    return this
  }

  setPage(page) {
    this.filters.page = page

    return this
  }

  getPage() {
    return parseInt(this.filters.page, 10)
  }

  setSortOn(field) {
    this.filters.sortOn = field
    return this
  }

  clearSort() {
    delete this.filters.sortOn
    delete this.filters.sortDirection

    return this
  }

  clearCategory() {
    delete this.filters.category

    return this
  }

  setSortDirection(direction) {
    this.filters.sortDirection = direction

    return this
  }

  setCategory(category) {
    this.filters.category = category

    return this
  }

  applyMinPrice() {
    const min = this.filters.minPrice

    if (min && !includes(this.appliedFilters, 'minPrice')) {
      this.applyPrefix()
      this.appliedFilters.push('minPrice')
      this.url += `min=${min}`
    }
  }

  applyMaxPrice() {
    const max = this.filters.maxPrice

    if (max) {
      this.applyPrefix()
      this.appliedFilters.push('maxPrice')
      this.url += `max=${max}`
    }
  }

  applyIsOnSale() {
    const sale = this.filters.isOnSale

    if (sale !== null) {
      this.applyPrefix()
      this.appliedFilters.push('isOnSale')
      this.url += `sale=${sale}`
    }
  }

  applyStores() {
    const stores = this.filters.stores

    if (stores.length) {
      this.applyPrefix()
      this.appliedFilters.push('store')
      this.url += stores.map(store => `store=${store}`).join('&')
    }
  }

  applyExcludeStores() {
    const stores = this.filters.excludeStores

    if (stores.length) {
      this.applyPrefix()
      this.appliedFilters.push('store')
      this.url += stores.map(store => `xstore=${store}`).join('&')
    }
  }

  applyPage() {
    const page = this.filters.page

    if (page > 1) {
      this.applyPrefix()
      this.appliedFilters.push('page')
      this.url += `page=${page}`
    }
  }

  applySortOn() {
    const field = this.filters.sortOn

    if (field !== UrlBuilder.SORT_ON_DEFAULT) {
      this.applyPrefix()
      this.appliedFilters.push('sortOn')
      this.url += `sort=${field}`
    }
  }

  getSortDirection() {
    return this.filters.sortDirection
  }

  applySortDirection() {
    const direction = this.filters.sortDirection
    const field = this.filters.sortOn

    if (field !== UrlBuilder.SORT_ON_DEFAULT) {
      this.applyPrefix()
      this.appliedFilters.push('sortDirection')
      this.url += `order=${direction}`
    }
  }

  getCategory() {
    return this.filters.category
  }

  applyCategory() {
    if (this.filters.category) {
      this.applyPrefix()
      this.appliedFilters.push('category')
      this.url += `category=${encodeURIComponent(this.filters.category)}`
    }
  }

  applyTheme() {
    const theme = this.filters.theme

    if (theme) {
      this.applyPrefix()
      this.appliedFilters.push('theme')
      this.url += `theme=${theme}`
    }
  }

  applyTerm() {
    const term = this.filters.term

    if (term) {
      this.applyPrefix()
      this.appliedFilters.push('term')
      this.url += `term=${term}`
    }
  }

  applyDialog() {
    const dialog = this.filters.dialog

    if (dialog) {
      this.applyPrefix()
      this.appliedFilters.push('dialog')
      this.url += `dialog=${dialog}`
    }
  }

  setDialog(dialog) {
    this.filters.dialog = dialog

    return this
  }

  applyDialogView() {
    const dialogView = this.filters.dialogView

    if (dialogView) {
      this.applyPrefix()
      this.appliedFilters.push('dialogView')
      this.url += `dialogView=${dialogView}`
    }
  }

  setDialogView(dialogView) {
    this.filters.dialogView = dialogView

    return this
  }

  applyDialogPanel() {
    const dialogPanel = this.filters.dialogPanel

    if (dialogPanel) {
      this.applyPrefix()
      this.appliedFilters.push('dialogPanel')
      this.url += `dialogPanel=${dialogPanel}`
    }
  }

  setDialogPanel(dialogPanel) {
    this.filters.dialogPanel = dialogPanel

    return this
  }

  hasFilters() {
    return true
  }

  getMethodName(key) {
    return 'apply' + key.charAt(0).toUpperCase() + key.slice(1)
  }

  applyAllfilters(filters) {
    if (this.hasFilters(filters)) {
      keys(filters).map(key => this[this.getMethodName(key)]())
    }
  }

  getQueryString() {
    this.applyAllfilters(this.filters)
    return this.url
  }
}
