import Component from './component'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { skin } from 'Utils'
import skins from './skins'
import { closeNavPanel } from 'Actions'

const mapDispatchToProps = dispatch => ({
  closeNavPanel: bindActionCreators(closeNavPanel, dispatch)
})

export default connect(null, mapDispatchToProps)(skin(Component, skins))
