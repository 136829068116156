import React from 'react'
import PropTypes from 'prop-types'
import { capitalize } from '../stringOperations'
import get from 'lodash/get'
import config from 'Config'
import { gaReady, gtagReady } from '../withGAEvent'

function GAEvent({
  amplitude,
  className,
  children,
  on = 'click',
  type = 'event',
  category = '',
  action = '',
  label = '',
  additionalProps = {},
}) {
  const event = {
    hitType: type,
    eventCategory: category,
    eventAction: action,
    eventLabel: label
  }

  const isWindowAmplitude =
    typeof window !== 'undefined' && window.amplitude !== 'undefined'

  const send = () => {
    if (config.amplitude.enabled && isWindowAmplitude) {
      const eventType = get(amplitude, 'eventType', false)
      const eventProps = get(amplitude, 'eventProps', false)
      if (eventType && eventProps) {
        /* eslint-disable no-undef */
        window.amplitude.getInstance().logEvent(eventType, eventProps)
        /* eslint-enable no-undef */
      }
    }
    if (gaReady()) {
      /* eslint-disable no-undef */
      ga('send', event)
      ga(`${process.env.ANT_GA_CLIENT_TRACKER_NAME}.send`, event)
      /* eslint-enable no-undef */
    }

    if (gtagReady) {
      /* eslint-disable no-undef */
      gtag(
      /* eslint-enable no-undef */
        'event',
        event.eventAction,
        {
          tracker: `${process.env.ANT_GA_CLIENT_TRACKER_NAME}.send`, 
          category: event.eventCategory,
          ...additionalProps,
        }
      )
    }

  }

  const settings = {
    [`on${capitalize(on || 'click')}`]: send
  }

  return (
    <span {...{ className }} {...settings}>
      {children}
    </span>
  )
}

GAEvent.propTypes = {
  amplitude: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.element,
  on: PropTypes.string,
  type: PropTypes.string,
  category: PropTypes.string,
  action: PropTypes.string,
  label: PropTypes.string,
  additionalProps: PropTypes.object,
}

export default GAEvent
