import { css } from '@emotion/core'
import { smallOnly, mediumUp } from 'Utils'
import theme from 'Theme'

export const plain = () => {
  const { colors, typography, mixins } = theme
  const { styledImage = () => {} } = mixins
  return css`
    position: relative;
    display: block;
    @media ${smallOnly} {
      max-width: 90%;
      margin: 0 auto;
    }

    &.active {
      outline: 2px solid ${colors.toolbar.backgroundColor};
    }

    .image {
      ${styledImage(theme)}
      width: auto;
      object-fit: cover;
      position: relative;
      @media ${mediumUp} {
        height: 250px;
        width: 100%;
      }
    }

    .article-panel-title {
      font-size: ${typography.sizes.large};
      margin: 1rem 0;
    }

    .article-created-block {
      margin-top: 1rem;
    }
  `
}

export default plain
