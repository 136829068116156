import PropTypes from 'prop-types'
import React from 'react'
import { Link, ContentCard, Grid } from 'Blocks'
import { path } from 'Utils'
import { PAGE_CONTEXT as p } from 'Constants'
import { ContentPanel } from './components'

const ContentsMenu = props => {
  const formatTypeName = type =>
    type.charAt(0).toUpperCase() + type.slice(1) + 's'
  const key = formatTypeName(props.activeContentType || p.ARTICLE)
  const data = props.data[`navPanel${key}`]
  const {
    contents,
    aggregations: { themes, celebrities }
  } = data

  const renderTitle = contentType => {
    let heading = 'Stories By Category'
    if (contentType === p.TREND) {
      heading = 'Shop Trends By Category  '
    }
    if (contentType === p.INSTALOOK) {
      heading = 'Shop By Celeb'
    }
    return heading
  }

  const renderPanelTitles = contentType => {
    let panelsHeading = 'Latest Stories'
    if (contentType === p.TREND) {
      panelsHeading = 'Latest Trends'
    }
    if (contentType === p.INSTALOOK) {
      panelsHeading = 'Latest Celebrity Style'
    }
    return panelsHeading
  }

  const isMobile = props.screenType === 'small'
  const contentType = props.activeContentType || p.ARTICLE
  const listPageName = `${contentType}List`
  const limit = props.screenType === 'medium' ? 2 : 3
  const filterData = contentType === p.INSTALOOK ? celebrities : themes
  const filterName = contentType === p.INSTALOOK ? 'celebrity' : 'theme'
  return (
    <div className={`${props.className} main-menu`}>
      {isMobile && (
        <div className={`main-menu__mobile-heading`}>
          <h2>{renderTitle(contentType)}</h2>
        </div>
      )}
      <div className="main-menu__container">
        <div className="main-menu__level-wrap">
          <div className="main-menu__level first-level">
            {!isMobile && (
              <h3 className="main-menu__heading">{renderTitle(contentType)}</h3>
            )}
            <ul className="filter-menu">
              <li className="filter-menu__item">
                <Link
                  onClick={props.closeNavPanel}
                  className="filter-menu__link"
                  to={path(listPageName)}
                >
                  Browse All
                </Link>
              </li>
              {filterData
                .filter(filter => filter.name)
                .map((filter, index) => (
                  <li
                    key={`content-filter-btn-${index}`}
                    className="filter-menu__item"
                  >
                    <Link
                      onClick={props.closeNavPanel}
                      to={path(
                        listPageName,
                        {},
                        `?${filterName}=${encodeURIComponent(filter.name)}`
                      )}
                      className="filter-menu__link"
                    >
                      {filter.name}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>

          {!isMobile && (
            <div className="content-panels__wrapper">
              <h2 className="content-panels__title">
                {renderPanelTitles(contentType)}
              </h2>
              <Grid className="content-panels" itemsPerRow={limit}>
                {contents.slice(0, limit).map((content, index) => {
                  return props.activeContentType === p.INSTALOOK ? (
                    <ContentCard
                      key={`content-${content.uuid}`}
                      position={index + 1}
                      useCategoryName
                      variant="Instalooks"
                      content={content}
                      pageName="nav-panel-content"
                    />
                  ) : (
                    <ContentPanel
                      key={`content-${content.uuid}`}
                      position={index + 1}
                      content={content}
                    />
                  )
                })}
              </Grid>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

ContentsMenu.propTypes = {
  activeContentType: PropTypes.any,
  className: PropTypes.any,
  closeNavPanel: PropTypes.any,
  data: PropTypes.any,
  screenType: PropTypes.string
}

export default ContentsMenu
