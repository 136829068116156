import React from 'react'
import PropTypes from 'prop-types'
import { skin, withGAEvent } from 'Utils'
import skins from './skins'
import { LabeledCheckbox, Link, Icon } from 'Blocks'
import config, { gaSelectors } from 'Config'
import { SCREEN_TYPE } from 'Constants'

const SaleFilter = props => {
  const {
    className,
    style,
    to,
    label,
    isSelected,
    isDisabled,
    screenType
  } = props

  const Block = props => {
    const domProps = Object.assign({}, props)
    delete domProps.children
    return <div {...domProps}>{props.children}</div>
  }

  Block.propTypes = {
    children: PropTypes.node
  }

  const SaleFilterWithGA = withGAEvent(Block, {
    action: gaSelectors.getSaleFilterSettings.action,
    category: gaSelectors.getSaleFilterSettings.category,
    label: `isSelected=${!isSelected}`,
    additionalProps: { isSelected }
  })

  const resolveSelected = () => (isSelected ? 'selected' : '')
  const resolveDisabled = () => (isDisabled ? 'disabled' : '')
  const displayLabel = label || config.filters.sale.label
  const displayCloseIcon =
    config.filters.sale.useCloseIcon && screenType !== SCREEN_TYPE.SMALL

  const SaleLink = () => (
    <Link to={to} className={`${resolveSelected()} ${resolveDisabled()}`}>
      {displayLabel}
      {displayCloseIcon && <Icon name="close" className="close-icon" />}
      {isSelected && <Icon name="close" className="close-icon" />}
    </Link>
  )

  const DisabledLink = () => (
    <span className={`${resolveSelected()} ${resolveDisabled()}`}>
      {displayLabel}
      {displayCloseIcon && <Icon name="close" className="close-icon" />}
      {isSelected && <Icon name="close" className="close-icon" />}
    </span>
  )

  return (
    <SaleFilterWithGA className={`sale-filter ${className}`} style={style}>
      {config.filters.sale.checkbox ? (
        <LabeledCheckbox
          {...{ to, isSelected, isDisabled }}
          label={displayLabel}
        />
      ) : isDisabled ? (
        <DisabledLink />
      ) : (
        <SaleLink />
      )}
    </SaleFilterWithGA>
  )
}

SaleFilter.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  isDisabled: PropTypes.any,
  isSelected: PropTypes.any,
  label: PropTypes.any,
  screenType: PropTypes.any,
  style: PropTypes.any,
  to: PropTypes.any
}

export default skin(SaleFilter, skins)
