import { spacing } from '../spacing'

export const articleTarget = {
  spacing: {
    small: spacing.vertical.small,
    large: spacing.vertical.large,
    paragraph: spacing.vertical.small
  }
}

export default articleTarget
