import PropTypes from 'prop-types'
import React from 'react'
import { skin } from 'Utils'
import { Link, Icon } from 'Blocks'
import skins from './skins'

const ColorFilter = props => {
  return (
    <ul className={`color-list list ${props.className}`}>
      {props.items.map(item => {
        const { s8color, to, isSelected, color } = item
        const selectedClass = isSelected ? 'selected' : ''
        const cssProp =
          s8color.indexOf('gradient') > -1 ? 'background' : 'backgroundColor'
        const style = {}
        style[cssProp] = s8color
        if (s8color.indexOf('white') > -1) {
          style.border = '1px solid #D0D0D0'
        }
        return (
          <li key={color} className={`list-item ${selectedClass}`}>
            <Link to={to}>
              <span className={`list-item__label color-fill`} {...{ style }} />
              <span className="list-item__label">{color}</span>
              {isSelected && <Icon name="close" className="close-icon" />}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

ColorFilter.propTypes = {
  className: PropTypes.any,
  items: PropTypes.array
}

ColorFilter.defaultProps = {
  items: []
}

export default skin(ColorFilter, skins)
