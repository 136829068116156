import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Icon, Link, CategoryPanels } from 'Blocks'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import { path, sendTrackingEvent } from 'Utils'
import { PAGE_CONTEXT as p } from 'Constants'
import config, { gaSettings } from 'Config'

const { navPanelCategoryData } = config
const { menu } = gaSettings

class MainMenu extends Component {
  static propTypes = {
    activeTopLevelCategory: PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string,
        uuid: PropTypes.string
      }),
      PropTypes.string
    ]),
    className: PropTypes.string,
    closeNavPanel: PropTypes.func,
    currentCategory: PropTypes.any,
    currentPath: PropTypes.array,
    isTouch: PropTypes.any,
    screenType: PropTypes.string,
    setCurrentCategory: PropTypes.func,
    setCurrentPath: PropTypes.func
  }

  constructor() {
    super()
    this.state = {
      currentCategory: '',
      levels: []
    }
  }

  handleHeadingClick = () => {
    if (this.props.screenType === 'small') {
      const topLevel = [process.env.ANT_BROWSE_ID]
      this.setState({
        levels: topLevel
      })
    }
  }

  handleLeafClick = uuid => {
    this.props.setCurrentCategory(uuid)
    this.props.setCurrentPath(this.state.levels)
    this.props.closeNavPanel()
  }

  getPathToCategory = (uuid, pathsArr) => {
    const parent = this.getParentCategory(uuid)
    if (parent) {
      pathsArr.unshift(parent)
      this.getPathToCategory(parent, pathsArr)
    } else {
      return false
    }
  }

  getParentCategory = uuid => {
    const categoryTree = get(this.props, 'categoryTree', [])
    for (var i = 0; i < categoryTree.length; i++) {
      if (categoryTree[i].children.length > 0) {
        if (categoryTree[i].children.includes(uuid)) {
          return categoryTree[i].uuid
        }
      }
    }
  }

  setPathToCategory = uuid => {
    let arr = []
    this.getPathToCategory(uuid, arr)
    arr.push(uuid)
    this.setState({
      levels: arr
    })
  }

  setPathToCategoryFromLeaf = uuid => {
    let arr = []
    this.getPathToCategory(uuid, arr)
    this.setState({
      levels: arr
    })
  }

  componentDidMount() {
    if (this.props.activeTopLevelCategory.uuid) {
      this.setPathToCategory(this.props.activeTopLevelCategory.uuid)
    }
    /* current top level clicked */
    if (
      this.props.currentPath.length >= 0 &&
      this.props.activeTopLevelCategory.uuid === this.props.currentPath[1]
    ) {
      const newLevels = [...this.props.currentPath]
      this.setState({
        levels: newLevels
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.activeTopLevelCategory.uuid !==
      prevProps.activeTopLevelCategory.uuid
    ) {
      this.setPathToCategory(this.props.activeTopLevelCategory.uuid)
    }
    /* current top level clicked */
    if (
      this.props.activeTopLevelCategory.uuid !==
        prevProps.activeTopLevelCategory.uuid &&
      this.props.currentPath.length >= 0 &&
      this.props.activeTopLevelCategory.uuid === this.props.currentPath[1]
    ) {
      const newLevels = [...this.props.currentPath]
      this.setState({
        levels: newLevels
      })
    }
  }

  isLeaf = uuid => {
    const category = this.getCategoryFromUuid(uuid)
    return category ? category.children.length === 0 : null
  }

  getCategoryFromUuid = uuid => {
    const categoryTree = get(this.props, 'categoryTree', [])
    const categoryFromTree = categoryTree.filter(
      category => category.uuid === uuid
    )
    return categoryFromTree.length > 0 ? categoryFromTree[0] : null
  }

  renderItem = ({ uuid, name, slug }) => {
    const isActiveCategory = this.state.levels.filter(level => level === uuid)
      .length
    const activeCategoryClass = isActiveCategory ? 'active-category' : ''
    const currentCategoryClass = this.props.currentPath.includes(uuid)
      ? 'current-category'
      : ''
    const isActiveParentClass =
      [...this.state.levels].pop() === uuid ? 'active-parent' : ''
    // TODO: not working on tablet devices - no MouseEnter event
    const eventName = this.props.screenType === 'small' ? 'Click' : 'MouseEnter'
    const event = {}
    event[`on${eventName}`] = () => this.setPathToCategory(uuid)
    const itemAsSpan = (
      <span
        className={`menu-item ${activeCategoryClass} ${currentCategoryClass} ${isActiveParentClass}`}
        {...event}
        key={`menu-item-${uuid}`}
      >
        {name}
        <Icon name="keyboard_arrow_right" />
      </span>
    )

    const url = path(p.PRODUCT_LIST, { uuid: uuid, subCategory: slug })

    const gaEvent = {
      category: menu.category,
      action: menu.action,
      label: `linkName=${name} - destinationUrl=${url}`
    }

    const itemAsLink = (
      <Link
        to={url}
        className={`menu-item ${activeCategoryClass} ${currentCategoryClass} ${isActiveParentClass}`}
        {...event}
        onClick={() => {
          sendTrackingEvent({ amplitude: {}, gaEvent, additionalProps: {
            linkName: name,
            destinationUrl: url,
          }})
          this.handleLeafClick(uuid)
        }}
        key={`menu-item-${uuid}`}
      >
        {name}
        <Icon name="keyboard_arrow_right" />
      </Link>
    )
    return this.props.isTouch
      ? itemAsSpan
      : this.props.screenType === 'small'
      ? itemAsSpan
      : itemAsLink
  }

  renderLink = ({ uuid, name, slug }) => {
    const url = path(p.PRODUCT_LIST, { uuid: uuid, subCategory: slug })
    const gaEvent = {
      category: menu.category,
      action: menu.action,
      label: `linkName=${name} - destinationUrl=${url}`
    }
    const currentCategoryClass =
      uuid === this.props.currentCategory ? 'current-category' : ''
    return (
      <Link
        onMouseEnter={() => this.setPathToCategoryFromLeaf(uuid)}
        onClick={() => {
          sendTrackingEvent({ amplitude: {}, gaEvent, additionalProps: {
            linkName: name,
            destinationUrl: url,
          }})
          this.handleLeafClick(uuid)
        }}
        className={`menu-item leaf-category ${currentCategoryClass}`}
        to={url}
        key={`menu-item-${uuid}`}
      >
        {name}
      </Link>
    )
  }

  renderList = uuid => {
    const categoryTree = get(this.props, 'categoryTree', [])
    const parentCategory = this.getCategoryFromUuid(uuid)

    // get the children
    if (parentCategory && parentCategory.children.length > 0) {
      let children = []
      parentCategory.children.forEach(uuid => {
        const child = categoryTree.filter(category => category.uuid === uuid)
        if (child.length > 0) {
          children.push(child[0])
        }
      })

      const orderedItems = sortBy(children, 'name')

      return orderedItems.map(category => {
        const { slug, uuid, name } = category
        return this.isLeaf(category.uuid)
          ? this.renderLink({ slug, uuid, name })
          : this.renderItem({ slug, uuid, name })
      })
    }
  }

  renderHeaderIcon = () => {
    let activeParentClass = ''
    if (this.state.levels.length === 1) {
      activeParentClass =
        this.state.levels[0] === process.env.ANT_BROWSE_ID
          ? 'active-parent'
          : ''
    }
    return this.props.screenType === 'small' ? (
      <Icon name="keyboard_arrow_right" className={activeParentClass} />
    ) : null
  }

  renderMobileHeading = () => {
    const categoryIndex = this.state.levels.length - 1
    const category = this.getCategoryFromUuid(this.state.levels[categoryIndex])
    let categoryName = get(category, 'name', 'Categories')
    const categoryUuid = get(category, 'uuid', '')

    if (categoryUuid === process.env.ANT_BROWSE_ID) {
      categoryName = 'Categories'
    }

    return <span>{categoryName}</span>
  }

  renderAllLink = uuid => {
    const parentCategory = this.getCategoryFromUuid(uuid)
    const currentCategoryClass =
      parentCategory.uuid === this.props.currentCategory
        ? 'current-category'
        : ''
    const url = path(p.PRODUCT_LIST, {
      uuid: parentCategory.uuid,
      subCategory: parentCategory.slug
    })
    const gaEvent = {
      category: menu.category,
      action: menu.action,
      label: `linkName=${parentCategory.name} - destinationUrl=${url}`
    }
    return (
      <Link
        onMouseEnter={() => this.setPathToCategory(uuid)}
        onClick={() => {
          sendTrackingEvent({ amplitude: {}, gaEvent: gaEvent })
          sendTrackingEvent({ amplitude: {}, gaEvent, additionalProps: {
            linkName: parentCategory.name,
            destinationUrl: url,
          }})
          this.handleLeafClick(uuid)
        }}
        className={`menu-item leaf-category ${currentCategoryClass}`}
        to={url}
        key={`menu-item-${uuid}`}
      >
        {`All ${parentCategory.name}`}
      </Link>
    )
  }

  render() {
    const categoryTree = get(this.props, 'categoryTree', [])
    const isMobile = this.props.screenType === 'small'
    const heading = isMobile ? 'All Categories' : 'Browse By Category'
    let levelsForDesktop = [...this.state.levels]
    // remove browse id
    levelsForDesktop.shift()
    const levels = isMobile ? this.state.levels : levelsForDesktop

    return (
      <div className={`${this.props.className} main-menu`}>
        {isMobile && (
          <div className={`main-menu__mobile-heading`}>
            <h2>{this.renderMobileHeading()}</h2>
          </div>
        )}

        <div className="main-menu__container">
          {isMobile && (
            <div
              onClick={this.handleHeadingClick}
              className="main-menu__header"
            >
              <h3 className="main-menu__heading">{heading}</h3>
              {this.renderHeaderIcon()}
            </div>
          )}
          <div className="main-menu__level-wrap">
            {!isMobile && navPanelCategoryData && (
              <CategoryPanels categoryTree={categoryTree} levels={levels} />
            )}
            {levels.map((level, index) => {
              const activeClass =
                this.state.levels.length - 1 === index ? `active-level` : ``
              const positionClass = index === 0 ? `first-level` : ``
              return level ? (
                <div
                  key={`level-${index}`}
                  className={`main-menu__level ${activeClass} ${positionClass}`}
                >
                  {!isMobile && index === 0 && (
                    <div className="main-menu__header">
                      <h3 className="main-menu__heading">{heading}</h3>
                    </div>
                  )}
                  {this.renderAllLink(level)}
                  {this.renderList(level)}
                </div>
              ) : null
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default MainMenu
