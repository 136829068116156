import { css } from '@emotion/core'
import baseStyles from './baseCustomSelect'

export default ({ theme }) => css`
  ${baseStyles}

  .dropdown-down-arrow {
    ${theme.mixins.downArrow(theme)}
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
`
