import { css } from '@emotion/core'

export default ({ theme: { spacing } }) => css`
  .no-match__info {
    margin: 0 auto;
    max-width: 400px;
    text-align: center;

    h2 {
      text-transform: uppercase;
      font-weight: normal;
    }

    &.error__page {
      h2 {
        letter-spacing: 0.5px;
      }
      p {
        letter-spacing: 0.5px;
      }
    }
  }

  .no-match__spacing {
    margin-bottom: ${spacing.vertical.small};
    margin-top: ${spacing.vertical.small};
  }
`
