import React from 'react'
import PropTypes from 'prop-types'
import { Link, Icon } from 'Blocks'
import { UrlBuilder } from 'Utils'

const DepartmentSort = props => {
  const { currentCategory, queryString, departments, className } = props

  const getLink = category => {
    const builder = new UrlBuilder(queryString)
    if (currentCategory === category) {
      builder.clearCategory()
    } else {
      builder.setCategory(category)
    }
    return builder.getQueryString()
  }

  const Links = departments.map(filter => ({
    label: filter.name,
    to: getLink(filter.name),
    selected: decodeURIComponent(currentCategory) === filter.name
  }))

  const DepartmentLink = ({ label, to, selected }) => (
    <Link className={selected ? 'selected' : ''} to={to}>
      {label}
      {selected && <Icon className="close-icon" name="close" />}
    </Link>
  )

  DepartmentLink.propTypes = {
    label: PropTypes.string,
    to: PropTypes.string,
    selected: PropTypes.any
  }

  return (
    <div {...{ className }}>
      <div className="links filter-links">
        {Links.map(link => (
          <DepartmentLink key={link.label} {...link} />
        ))}
      </div>
    </div>
  )
}

DepartmentSort.propTypes = {
  className: PropTypes.any,
  currentCategory: PropTypes.any,
  departments: PropTypes.array,
  queryString: PropTypes.any
}

export default DepartmentSort
