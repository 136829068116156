import { createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import rootReducer from './reducers'
import { hmrAction } from './actions'

export default function configureStore({ state: preloadedState }) {
  const store = createStore(rootReducer, preloadedState, composeWithDevTools())

  if (module.hot) {
    // TODO: doesn't replace initialState
    module.hot.accept('./reducers', () => {
      store.dispatch(hmrAction())
      const nextRootReducer = require('./reducers').default
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}
