import loadable from '@loadable/component'

export const Home = loadable(() => import('./components/Home'))
export const ArticlePage = loadable(() => import('./components/ArticlePage'))
export const ArticleListPage = loadable(() =>
  import('./components/ArticleListPage')
)
export const TrendPage = loadable(() => import('./components/TrendPage'))
export const TrendListPage = loadable(() =>
  import('./components/TrendListPage')
)
export const InstalookPage = loadable(() =>
  import('./components/InstalookPage')
)
export const InstalookListPage = loadable(() =>
  import('./components/InstalookListPage')
)
export const ProductPage = loadable(() => import('./components/ProductPage'))
export const ProductListPage = loadable(() =>
  import('./components/ProductListPage')
)
export const ComparePage = loadable(() => import('./components/ComparePage'))
export const TermsAndConditionsPage = loadable(() =>
  import('./components/staticPages/TermsAndConditionsPage')
)
export const PricingAndStockIssues = loadable(() =>
  import('./components/staticPages/PricingAndStockIssues')
)
export const EmbedPage = loadable(() => import('./components/EmbedPage'))
export const Favourites = loadable(() => import('./components/Favourites'))
