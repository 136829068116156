import { css } from '@emotion/core'

const plain = ({ theme: { mixins, colors } }) => css`
  &.visible {
    -webkit-animation: FadeIn 100ms ease-in;
    -moz-animation: FadeIn 100ms ease-in;
    animation: FadeIn 100ms ease-in;

    @-webkit-keyframes FadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @-moz-keyframes FadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes FadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  &.invisible.loading {
    background-color: gainsboro;
  }
  &.loading {
    ${mixins.loadingBackground(
      'gainsboro',
      colors.loading.panel.light.backgroundColor
    )}
  }
  &.missing {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 60px;
      height: 60px;
      fill: ${colors.borderGreyLight};
    }
  }
`
export default plain
