import { colors } from '../colors'

export default {
  height: '3rem',
  width: '300px',
  backgroundColor: `${colors.toolbar.backgroundColor}`,
  fontFamily: `Helvetica Neue, helvetica, arial, verdana, sans-serif;`,
  textColor: `${colors.toolbar.textColor}`,
  transitionDuration: '200ms',
  transitionTiming: 'ease-out',
  article: {
    status_draft: `#CACFD2`,
    status_change_request: `#8E44AD`,
    status_approval_request: `#F1C40F`,
    status_published: `#008C0C`,
    status_deleted: `#E74C3C`
  }
}
