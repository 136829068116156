import base from './base'
import merge from 'lodash/merge'
import { PAGE_CONTEXT as p } from 'Constants'

export default merge(base, {
  name: 'ff',
  group: 'plain',
  apiKey: 'tynCBrgx3b3SXchEJqu84bm3ZBrM5nvWvr6eNpfAV3CWfBfP',
  siteVerifyCode: 'cgKigSMGfNgxLs_vWG5cbD-efASh5ivoiwCzEsa6BK0',
  useEmbedDemo: true,
  showSearchInTopBar: true,
  preloadCategoryImages: true,
  altClickrefEnabled: true,
  useLoadMoreUI: false,
  categorySlotText: 'See More',
  categoryTitle: 'SHOP BY CATEGORY',
  articlesOnProductListPage: false,
  compareCategoryLinksEnabled: true,
  buyButton: {
    label: 'Shop it now'
  },
  helmet: {
    partner: 'Fevuh',
    defaultDescription: `Whether you're on the hunt for fashion, beauty, homeware or the perfect gift for that special someone, you'll find it here at Fevuh. Discover our top product picks and best-rated experiences now`
  },
  siteWide: {
    searchPlaceholderText: 'Search for items'
  },
  home: {
    panels: [
      {
        uuid: '22b3cf9f84e0',
        name: 'Clothing',
        description:
          'All the best women’s fashion pieces to add to your wardrobe pronto',
        image: {
          small:
            'https://ik.imagekit.io/sqhmihmlh/tr:w-800/gr/clothing_small.jpg',
          large:
            'https://ik.imagekit.io/sqhmihmlh/tr:w-800/gr/clothing_large.jpg'
        }
      },
      {
        uuid: '74e54526',
        name: 'Shoes',
        description:
          'Fancy footwear and casual classics to keep your feet happy',
        image: {
          small:
            'https://ik.imagekit.io/sqhmihmlh/tr:w-800/gr/shoes_small.jpeg',
          large: 'https://ik.imagekit.io/sqhmihmlh/tr:w-800/gr/shoes_large.jpg'
        }
      },
      {
        uuid: '5a29bae5',
        name: 'Accessories',
        description:
          'Designer bags and luxe little details to round off every look',
        image: {
          small:
            'https://ik.imagekit.io/sqhmihmlh/tr:w-800/gr/accesories_small.jpg',
          large:
            'https://ik.imagekit.io/sqhmihmlh/tr:w-800/gr/accesories_large.jpg'
        }
      },
      {
        uuid: '16595947',
        name: 'Make-Up & Fragrance',
        description:
          'Essential scents and beauty must-haves to add to your regime right now',
        image: {
          small:
            'https://ik.imagekit.io/sqhmihmlh/tr:w-800/gr/makeupFragrance_small.jpg',
          large:
            'https://ik.imagekit.io/sqhmihmlh/tr:w-800/gr/makeupFragrance_large.jpg'
        }
      },
      {
        uuid: '4b8be08c',
        name: 'Skin & Body Care',
        description: 'Dream skin starts with these industry-leading products',
        image: {
          small:
            'https://ik.imagekit.io/sqhmihmlh/tr:w-800/gr/skinBodyCare_small.jpg',
          large:
            'https://ik.imagekit.io/sqhmihmlh/tr:w-800/gr/skinBodyCare_large.jpg'
        }
      },
      {
        uuid: '4988ad21',
        name: 'Haircare & Styling',
        description: 'The most wanted products and styling for every hair type',
        image: {
          small:
            'https://ik.imagekit.io/sqhmihmlh/tr:w-800/gr/haircareStylin_small.jpeg',
          large:
            'https://ik.imagekit.io/sqhmihmlh/tr:w-800/gr/haircareStylin_large.jpeg'
        }
      },
      {
        uuid: '7c8a153fed14',
        name: 'Home & Garden',
        description:
          'Inspire your interiors with the latest furnishings and accessories',
        image: {
          small: 'https://ik.imagekit.io/sqhmihmlh/tr:w-800/gr/home_small.jpg',
          large: 'https://ik.imagekit.io/sqhmihmlh/tr:w-800/gr/home_large.jpg'
        }
      },
      {
        uuid: '15e61cbb966e',
        name: 'Kids & Baby',
        description:
          'Love your little one with tiny toys and the cutest clothing',
        image: {
          small: 'https://ik.imagekit.io/sqhmihmlh/tr:w-800/gr/kids_small.jpg',
          large: 'https://ik.imagekit.io/sqhmihmlh/tr:w-800/gr/kids_large.jpg'
        }
      }
    ]
  },
  pagination: {
    productList: 30,
    altProducts: 6,
    compare: 27
  },
  productList: {
    slots: {
      useContentListItem: false
    }
  },
  filters: {
    sale: {
      checkbox: true
    }
  },
  compareTitle: 'More matches',
  compareLinkLabel: 'More matches',
  articlesEnabled: false,
  heroEnabled: true,
  heroImage: {
    small: 'https://ik.imagekit.io/sqhmihmlh/ff/hero-bg.png',
    large: 'https://ik.imagekit.io/sqhmihmlh/ff/hero-bg.png'
  },
  heroTitle: 'Fevuh',
  heroCopy: `Whether you're on the hunt for fashion, beauty, homeware or the perfect gift for that special someone, you'll find it here at Fevuh. Discover our top product picks and best-rated experiences now`,
  shopPath: process.env.ANT_PREFIX,

  routes: {
    [p.TERMS]: {
      page: 'TermsAndConditionsPage',
      path: `${process.env.ANT_PREFIX}/TermsAndConditions`
    }
  }
})
