import { ACTION_TYPES as types } from 'Constants'

export default (
  state = {
    isActive: false,
    activeTopLevelCategory: '',
    currentPath: [],
    currentCategory: '',
    categoryTree: [],
    activeContentType: ''
  },
  action = {}
) => {
  switch (action.type) {
    case types.SET_ACTIVE_TOP_CATEGORY:
      return {
        ...state,
        activeTopLevelCategory: action.payload
      }
    case types.SET_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: action.payload
      }
    case types.SET_CURRENT_PATH:
      return {
        ...state,
        currentPath: action.payload
      }
    case types.STORE_CATEGORY_TREE:
      return {
        ...state,
        categoryTree: action.payload
      }
    case types.SET_ACTIVE_CONTENT_TYPE:
      return {
        ...state,
        activeContentType: action.payload
      }
    default:
      return state
  }
}
