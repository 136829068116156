import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { routerPropTypes } from 'Types'
import get from 'lodash/get'
import { skin } from 'Utils'
import skins from './skins'
import config from 'Config'

import {
  PriceFilter,
  SaleFilter,
  ExtendedStoreFilter,
  DepartmentSort,
  ColorFilter,
  Sorting,
  ItemsFound
} from 'Blocks'

class PanelFilters extends Component {
  static propTypes = {
    className: PropTypes.any,
    currentCat: PropTypes.array,
    data: PropTypes.shape({
      products: PropTypes.shape({
        aggregations: PropTypes.shape({
          departments: PropTypes.any
        })
      })
    }),
    loading: PropTypes.any,
    ...routerPropTypes
  }

  renderColorFilter = () => {
    const { data, location } = this.props
    const queryString = location.search
    const colorData = get(
      data,
      'products.categories.current.filters[0].values',
      []
    )
    return config.filters.colors.enabled && colorData.length > 0 ? (
      <div className="panel panel-filters__color">
        <h2 className="panel-filters__title">Colours</h2>
        <ColorFilter data={data} queryString={queryString} />
      </div>
    ) : null
  }

  render() {
    const { currentCat, data, location } = this.props
    const searchTitle =
      location.search.indexOf('term') !== -1
        ? location.search.match(/term=([^&]*)/)[1]
        : null
    const title = searchTitle || currentCat[currentCat.length - 1].name
    const queryString = location.search
    const isSaleFilterDisabled = !get(
      data,
      'products.products.aggregations.sales',
      true
    )

    return (
      <div className={`${this.props.className} panel-filters`}>
        <div className="panel-location-title">{decodeURIComponent(title)}</div>
        <ItemsFound loading={this.props.loading} />
        <div className="panel-filters__filters">
          {searchTitle && (
            <div className="panel panel-filters__department">
              <h2 className="panel-filters__title">Department</h2>
              <DepartmentSort
                departments={data.products.aggregations.departments}
                queryString={queryString}
                location={location}
              />
            </div>
          )}
          <div className="panel panel-filters__sort">
            <h2 className="panel-filters__title">
              {config.sorting.title ? config.sorting.title : 'Sort by price'}
            </h2>
            <Sorting queryString={queryString} location={location} />
          </div>
          <div className="panel panel-filters__price">
            <h2 className="panel-filters__title">
              {config.filters.price.title
                ? config.filters.price.title
                : 'Price range'}
            </h2>
            <PriceFilter queryString={queryString} data={data} />
            <SaleFilter
              queryString={queryString}
              isDisabled={isSaleFilterDisabled}
            />
          </div>
          {this.renderColorFilter()}
          <div className="panel panel-filters__store">
            <ExtendedStoreFilter data={data} queryString={queryString} />
          </div>
        </div>
      </div>
    )
  }
}

export default skin(PanelFilters, skins)
