import { ACTION_TYPES as types } from 'Constants'
import { isClient } from 'Utils'
import uniq from 'lodash/uniq'

function updateLocalStorage (key, value) {
  if (isClient) {
    window.localStorage.setItem(key, JSON.stringify(value))
  }
}

const getSmallProduct = ({
  affiliateUrl,
  brand,
  description,
  flags,
  image,
  prices,
  slug,
  store,
  title,
  uuid
}) => ({
  affiliateUrl,
  brand,
  description,
  ...{ flags },
  image,
  ...{ prices },
  slug,
  ...{ store },
  title,
  uuid
})

export default (state = {
  uuids: [],
  smallProducts: {}
}, action = {}) => {
  switch (action.type) {
    case types.FAVOURITES_FROM_LOCALSTORAGE: {
      const initialUuids = action.payload
      if (!Array.isArray(initialUuids) || initialUuids.length < 1) {
        return {...state}
      }
      const newUuids = [...state.uuids, ...initialUuids]
      return {
        ...state,
        uuids: uniq(newUuids)
      }
    }
    case types.ADD_TO_FAVOURITES: {
      const productUuid = action.payload.uuid
      if (!productUuid) return {...state}
      const smallProduct = getSmallProduct(action.payload)
      const newUuids = [...state.uuids]
      newUuids.unshift(productUuid)

      const newSmallProducs = {...state.smallProducts}
      newSmallProducs[productUuid] = smallProduct

      updateLocalStorage('favouritesUuids', uniq(newUuids))
      // updateLocalStorage('favouritesSmallProducts', newSmallProducs)
      return {
        ...state,
        uuids: uniq(newUuids),
        smallProducts: newSmallProducs
      }
    }
    case types.REMOVE_FROM_FAVOURITES: {
      const productUuid = action.payload
      if (!productUuid) return {...state}
      let newUuids = [...state.uuids]
      newUuids = uniq(newUuids)
      newUuids.splice(newUuids.indexOf(productUuid), 1)
      const newSmallProducs = {...state.smallProducts}
      if (newSmallProducs[productUuid]) {
        delete newSmallProducs[productUuid]
      }
      updateLocalStorage('favouritesUuids', newUuids)
      // updateLocalStorage('favouritesSmallProducts', newSmallProducs)
      return {
        ...state,
        uuids: newUuids,
        smallProducts: newSmallProducs
      }
    }

    default:
      return state
  }
}
