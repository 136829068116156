import React from 'react'
import PropTypes, { string } from 'prop-types'
import { Link as CoreLink } from 'react-router-dom'

const removeKeyProp = props => {
  const domProps = { ...props }

  const keyIndex = Object.keys(props).indexOf('key')
  if (keyIndex !== -1) {
    delete domProps['key']
  }

  const childrenOnlyIndex = Object.keys(props).indexOf('childrenOnly')
  if (childrenOnlyIndex !== -1) {
    delete domProps['childrenOnly']
  }

  const tokenIndex = Object.keys(props).indexOf('token')
  if (tokenIndex !== -1) {
    delete domProps['token']
  }

  const dispatchIndex = Object.keys(props).indexOf('dispatch')
  if (dispatchIndex !== -1) {
    delete domProps['dispatch']
  }

  const partnerShortNameIndex = Object.keys(props).indexOf('partnerShortName')
  if (partnerShortNameIndex !== -1) {
    delete domProps['partnerShortName']
  }

  const shouldRenderIndex = Object.keys(props).indexOf('shouldRender')
  if (shouldRenderIndex !== -1) {
    delete domProps['shouldRender']
  }

  return domProps
}

export class Link extends React.Component {
  static propTypes = {
    childrenOnly: PropTypes.any,
    shouldRender: PropTypes.bool
  }

  isInternal(to) {
    return to.indexOf('//') === -1
  }

  render() {
    const { shouldRender = true, childrenOnly } = this.props

    const domProps = removeKeyProp(this.props)
    const { to, children, ...rest } = domProps
    const isInternal = this.isInternal(to)

    const link = isInternal ? (
      <CoreLink to={to} {...rest}>
        {children}
      </CoreLink>
    ) : (
      <a href={to} {...rest}>
        {children}
      </a>
    )

    return childrenOnly ? children : shouldRender ? link : null
  }

  static get propTypes() {
    return {
      to: string
    }
  }
}

export default Link
