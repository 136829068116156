import { css } from '@emotion/core'

const getLayout = props => {
  let { itemsPerRow, gutterSize } = props.theme.layout

  if (props.itemsPerRow) {
    itemsPerRow = props.itemsPerRow
  } else {
    itemsPerRow = itemsPerRow[props.pageName]
      ? itemsPerRow[props.pageName][props.screenType] ||
        itemsPerRow[props.pageName].fallback
      : itemsPerRow.default[props.screenType] || itemsPerRow.default.fallback
  }
  gutterSize = gutterSize[props.pageName]
    ? gutterSize[props.pageName][props.screenType] ||
      gutterSize[props.pageName].fallback
    : gutterSize[props.screenType] || gutterSize.fallback

  const totalGuttering = gutterSize * itemsPerRow - gutterSize
  return {
    cardWidth: `calc(${100 / itemsPerRow}% - ${
      totalGuttering / itemsPerRow
    }px)`,
    lastItemInRow: `${itemsPerRow}n+${itemsPerRow}`,
    itemsPerRow,
    gutterSize
  }
}

export default props => {
  const layout = getLayout(props)

  return css`
    width: 100%;
    /* Flexbox Fallback - START */
    display: flex;
    flex-wrap: wrap;

    > * {
      width: ${layout.cardWidth};
      max-width: ${layout.cardWidth};
      flex: 1 1 ${layout.cardWidth};

      margin-right: ${layout.gutterSize}px;
      margin-bottom: ${layout.gutterSize}px;

      &:nth-of-type(${layout.lastItemInRow}) {
        margin-right: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
    /* Flexbox Fallback - END */

    display: grid;
    grid-template-columns: repeat(
      ${layout.itemsPerRow},
      minmax(${layout.cardWidth}, ${layout.cardWidth})
    );
    grid-gap: ${layout.gutterSize}px;

    /* Flex clean up - START */
    @supports (display: grid) {
      > * {
        width: auto;
        max-width: none;
        margin-right: 0;
        margin-bottom: 0;
      }
    }
    /* Flex clean up - END */
  `
}
