export const sanitize = text => {
  let entities = {
    amp: '&',
    apos: "'",
    '#x27': "'",
    '#x2F': '/',
    '#39': "'",
    '#47': '/',
    lt: '<',
    gt: '>',
    nbsp: ' ',
    quot: '"'
  }
  const stripTags = text.replace(/(<([^>]+)>)/gi, '')
  const stripEntities = stripTags.replace(
    /&([^;]+);/gm,
    function (match, entity) {
      return entities[entity] || match
    }
  )
  return stripEntities
}

export default sanitize
