import { css } from '@emotion/core'

export const plain = ({ theme: { colors }, isSelected }) => css`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${isSelected ? colors.primary : colors.body};

  &:hover {
    cursor: pointer;
    color: ${colors.primary};
  }

  .checkbox-wrapper {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 10px 0 0;
    border: 1px solid ${isSelected ? colors.primary : colors.body};
    transition: box-shadow 0.2s ease-out;
    display: flex;

    &:hover {
      box-shadow: inset 0 1px 2px ${colors.secondary};
    }
  }

  .checkbox {
    width: 100%;
  }

  .checkbox {
    background: ${isSelected ? colors.primary : colors.complementary.primary};
  }

  &.disabled {
    color: ${colors.disabled};
    &:hover {
      cursor: auto;
      color: ${colors.disabled};
    }
    .checkbox-wrapper {
      border: 1px solid ${colors.disabled};
      &:hover {
        box-shadow: none;
      }
    }
  }
`

export default plain
