import { SCREEN_TYPE, BREAK_POINT } from 'Constants'

const getScreenType = userAgent => {
  const { isMobile, isDesktop, isTablet, isiPad } = userAgent
  let screenType = null

  if (isMobile) {
    screenType = SCREEN_TYPE.SMALL
  }

  if (isTablet || isiPad) {
    screenType = SCREEN_TYPE.MEDIUM
  }

  if (isDesktop) {
    screenType = SCREEN_TYPE.LARGE
  }

  return screenType
}

const shouldRenderSmall = width => width <= BREAK_POINT.SMALL
const shouldRenderMedium = width => width <= BREAK_POINT.MEDIUM
const shouldRenderLarge = width => width <= BREAK_POINT.LARGE
const shouldRenderXL = width => width <= BREAK_POINT.XLARGE
const shouldRenderXXL = width => width > BREAK_POINT.XLARGE

const smallUp = `screen !default`
const smallOnly = `screen and (max-width: ${BREAK_POINT.SMALL}px)`
const mediumUp = `screen and (min-width: ${BREAK_POINT.SMALL + 1}px)`
const mediumOnly = `${mediumUp} and (max-width: ${BREAK_POINT.MEDIUM}px)`
const largeUp = `screen and (min-width: ${BREAK_POINT.MEDIUM + 1}px)`
const largeOnly = `${largeUp} and (max-width: ${BREAK_POINT.LARGE}px)`
const xLargeUp = `screen and (min-width: ${BREAK_POINT.LARGE + 1}px)`
const xLargeOnly = `${xLargeUp} and (max-width: ${BREAK_POINT.XLARGE}px)`

export {
  getScreenType,
  shouldRenderSmall,
  shouldRenderMedium,
  shouldRenderLarge,
  shouldRenderXL,
  shouldRenderXXL,
  smallUp,
  smallOnly,
  mediumUp,
  mediumOnly,
  largeUp,
  largeOnly,
  xLargeUp,
  xLargeOnly
}
