import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import { Icon } from 'Blocks'
import { CSSTransition } from 'react-transition-group'
import config from 'Config'
import throttle from 'lodash/throttle'

const ToTopButtonWrapper = props => {
  const isClient = typeof window !== 'undefined'
  return !isClient ? null : <ToTopButton {...props} />
}

const ToTopButton = props => {
  function topFct() {
    document.body.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const [show, setShow] = useState(false)
  const threshold = 1200
  const margin = document.body.scrollHeight / threshold > 2

  const scrollPosition = useCallback(
    throttle(
      () => {
        const currentSrollPosition = window.scrollY
        setShow(currentSrollPosition > threshold)
      },
      500,
      { leading: false }
    ),
    [setShow]
  )

  useEffect(() => {
    window.addEventListener('scroll', scrollPosition)
    return () => {
      window.removeEventListener('scroll', scrollPosition)
    }
  }, [scrollPosition, show])

  return (
    config.useScrollButton &&
    margin && (
      <CSSTransition in={show} unmountOnExit timeout={2000}>
        <button
          className={`${props.className} scroll-to-top-button`}
          onClick={topFct}
        >
          <Icon name={'to-top'} />
        </button>
      </CSSTransition>
    )
  )
}

ToTopButton.propTypes = {
  className: PropTypes.any
}

export default ToTopButtonWrapper
