import base from '../base'
import merge from 'lodash/merge'
import skins from './skins'
import components from './components'
import mixins from './mixins'
import colors from './colors'
import zIndex from './zIndex'
import spacing from './spacing'
import typography from './typography'
import { elements } from './elements'
import layout from './layout'

const theme = merge(base, {
  elements,
  colors,
  zIndex,
  mixins,
  typography,
  layout,
  spacing,
  components: {
    ...base.components,
    panel: {
      ...base.components.panel,
      color: colors.primary,
      hoverColor: colors.complementary.primary
    },
    ...components
  },
  skins
})

export default theme
