import { css } from '@emotion/core'
import { smallOnly, mediumUp, mediumOnly, largeUp } from 'Utils'
import theme from 'Theme'
const { layout, colors, typography, mixins } = theme

const gradientHeight = '80px'

export default () => {
  return css`
    width: 100%;

    @media ${mediumUp} {
      height: ${layout.navPanel.height - 1}px;
      background-color: ${colors.white};
      position: relative;
      z-index: 9;
    }

    @media ${smallOnly} {
      background-color: #f7f6f5;
      position: fixed;
      top: 65px;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9;
    }

    .main-menu__container {
      width: 100%;
      max-width: ${layout.maxWidth.mainContainer}px;
      margin: 0 auto;
      height: ${layout.navPanel.height}px;

      @media ${smallOnly} {
        height: calc(100vh - 65px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
      }

      @media ${mediumUp} {
        padding: 16px 0 16px 8px;
      }
    }

    .main-menu__mobile-heading {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      font-size: 20px;
      font-weight: normal;
      line-height: 1em;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
      color: white;

      .main-menu__close {
        align-self: flex-end;
        right: 8px;
        svg {
          fill: white;
          stroke: white;
        }
      }

      h2 {
        font-size: ${typography.sizes.large};
      }
    }

    .main-menu__heading {
      @media ${smallOnly} {
        width: 100%;
      }

      @media ${mediumUp} {
        padding: 0 16px 4px 8px;
        font-size: ${typography.sizes.small};
        font-weight: normal;
        color: ${colors.secondary};
      }
    }

    .main-menu__close {
      position: absolute;
      right: 18px;
      top: 16px;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      svg[name='menu-close'] {
        height: 20px;
        fill: ${colors.body};
        stroke: ${colors.body};
        @media ${smallOnly} {
          fill: white;
          stroke: white;
        }
      }
    }

    .main-menu__level-wrap {
      position: relative;
      display: flex;
      position: relative;
      flex-direction: column;
      min-height: ${gradientHeight};

      @media ${mediumUp} {
        flex-direction: row;
      }
    }

    .main-menu__level {
      @media ${smallOnly} {
        &.active-level {
          padding-bottom: 160px;
          .menu-item {
            display: block;
          }
        }
      }

      @media ${mediumUp} {
        position: relative;
        z-index: 2;
        background-color: ${colors.white};
        padding-left: 8px;
        padding-right: 8px;
        height: ${layout.navPanel.height - 32}px;
        overflow-y: auto;
        border-left: 1px solid ${colors.borderGrey};
        width: 33.3%;
        ${mixins.transparencyGradient(theme)}

        &.first-level {
          border-left: none;
        }

        > * {
          &:last-child {
            margin-bottom: 16px;
          }
        }

        &:not(.first-level) {
          padding-top: 22px;
        }
      }

      @media screen and (min-width: 1100px) {
        width: 25%;
        &::before {
          width: 25%;
        }
      }
    }

    .loading {
      width: 100%;
      height: 100%;
      background-color: darkgray;
    }

    .filter-menu__link {
      display: block;
      ${mixins.navPanelListItem(theme)}
    }

    .content-panels__wrapper {
      padding-right: 20px;

      @media ${mediumOnly} {
        width: 66.66%;
      }

      @media ${mediumUp} {
        padding-left: 20px;
        border-left: 1px solid ${colors.borderGrey};
        height: 413px;
      }

      @media ${largeUp} {
        width: 75%;
      }
    }

    .content-panels__title {
      margin-bottom: 10px;
    }
  `
}
