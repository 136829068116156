import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { loadableReady } from '@loadable/component'
import { HelmetProvider } from 'react-helmet-async'
import { CookiesProvider } from 'react-cookie'
import { ApolloProvider } from '@apollo/client'
import { createApolloClientClient, ResponsiveProvider } from 'Utils'

import { createBrowserHistory } from 'history'
import App from './App'
import configureStore from './configureStore'

const preloadedState = window.__REDUX_STATE__
delete window.__REDUX_STATE__
const client = createApolloClientClient()
delete window.__APOLLO_STATE__

const store = configureStore({ state: preloadedState })

const WebApp = props => (
  <HelmetProvider>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ResponsiveProvider>
          <Router history={createBrowserHistory()}>
            <CookiesProvider>
              <App />
            </CookiesProvider>
          </Router>
        </ResponsiveProvider>
      </Provider>
    </ApolloProvider>
  </HelmetProvider>
)

const root = document.getElementById('root')

if (process.env.NODE_ENV === 'production') {
  loadableReady(() => {
    ReactDOM.hydrate(<WebApp />, root)
  })
} else {
  ReactDOM.hydrate(<WebApp />, root)
}

if (module.hot && process.env.NODE_ENV === 'development') {
  module.hot.accept()
}
