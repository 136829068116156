export const getUrlParameter = ({ name, location }) => {
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  var results = regex.exec(location.search)
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const getSearchTerm = ({ location }) => {
  return getUrlParameter({ name: 'term', location })
}

export const getThemeFromQueryString = ({ location }) => {
  return getUrlParameter({ name: 'theme', location })
}
