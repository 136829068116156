import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import MainMenu from './component'
import {
  toggleNavPanel,
  closeNavPanel,
  openNavPanel,
  setCurrentPath,
  setCurrentCategory
} from 'Actions'
import { skin } from 'Utils'
import skins from './skins'

const mapStateToProps = (state, props) => ({
  screenType: state.UI.screenType,
  isTouch: state.UI.isTouch,
  navPanel: state.UI.navPanel,
  activeTopLevelCategory: state.UI.mainMenu.activeTopLevelCategory,
  currentPath: state.UI.mainMenu.currentPath,
  currentCategory: state.UI.mainMenu.currentCategory,
  categoryTree: state.UI.mainMenu.categoryTree
})

const mapDispatchToProps = dispatch => ({
  setCurrentPath: bindActionCreators(setCurrentPath, dispatch),
  setCurrentCategory: bindActionCreators(setCurrentCategory, dispatch),
  closeNavPanel: bindActionCreators(closeNavPanel, dispatch),
  openNavPanel: bindActionCreators(openNavPanel, dispatch),
  toggleNavPanel: bindActionCreators(toggleNavPanel, dispatch)
})

export default skin(
  connect(mapStateToProps, mapDispatchToProps)(MainMenu),
  skins
)
