import { gql } from '@apollo/client'

const categoryFragment = /* GraphQL */ gql`
  fragment categoryFields on Category {
    description
    image
    name
    parentUuid
    position
    slug
    uuid
  }
`
export default categoryFragment
