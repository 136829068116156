import { combineReducers } from 'redux'
import UI from './UI'
import journey from './Journey'

const rootReducer = combineReducers({
  UI,
  journey
})

export default rootReducer
