export default {
  Arrow: 'plain',
  Menu: 'plain',
  OffCanvas: 'plain',
  Icon: 'plain',
  CategoryCardList: 'plain',
  CategoryCard: 'plain',
  Carousel: 'plain',
  Breadcrumbs: 'plain',
  ArrowIcon: 'plain',
  ProductDetails: 'plain',
  PriceFilter: 'plain',
  LabeledCheckboxOnClick: 'plain',
  ProductCardPageLink: 'plain',
  ProductCardAffiliateLink: 'plain',
  Grid: 'plain',
  SaleFilter: 'plain',
  CategoryFilter: 'plain',
  CategorySlider: 'plain',
  StoreListFilter: 'plain',
  Pagination: 'plain',
  Sorting: 'plain',
  SortingButton: 'plain',
  TogglePanel: 'plain',
  Title: 'plain',
  Toggle: 'plain',
  PanelCard: 'plain',
  ContentCard: 'plain',
  ArticleIntro: 'plain',
  ArticleItem: 'plain',
  RelatedArticlesList: 'plain',
  RefineButton: 'plain',
  TopTrends: 'plain'
}
