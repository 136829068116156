export const typography = {
  headings: {
    letterSpacing: '.005em',
    lineHeight: '1em',
    fontWeight: 'normal',
    fontFamily: `"leagueGothic", 'helvetica, arial, verdana, sans-serif'`
  },
  body: {
    fontFamily: 'sans-serif, helvetica, arial, verdana'
  },
  sizes: {
    xsmall: '0.8rem',
    small: '0.9rem',
    large: '24px',
    larger: '30px',
    xlarge: '56px'
  },
  baseFontSize: '16px'
}
export default typography
