const articleProductCard = {
  product: {
    title: {
      fontSize: '.9em',
      lineHeight: '1.3'
    }
  }
}

export default articleProductCard
