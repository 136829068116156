import Component from './component'
import get from 'lodash/get'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  // TODO: either data or UI.filters need to be renamed
  data: get(state, 'UI.filters', {}),
  location: get(state, 'UI.location', {}),
  searchTerm: get(state, 'UI.location.search', ''),
  topLevelCat: get(state, 'UI.mainMenu.activeTopLevelCategory.name', ''),
  currentCat: get(state, 'journey.track.items', '')
})

export default connect(mapStateToProps, null)(Component)
