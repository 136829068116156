import { ACTION_TYPES as types } from 'Constants'
import { combineReducers } from 'redux'
import navPanel from './navPanel'
import mainMenu from './mainMenu'
import location from './location'
import loading from './loading'
import filters from './filters'
import favourites from './favourites'

const flyout = (
  state = {
    isActive: false
  },
  action = {}
) => {
  switch (action.type) {
    case types.TOGGLE_FLY_OUT_PANEL:
      return {
        ...state,
        isActive: !state.isActive
      }
    default:
      return state
  }
}

const screenType = (state = {}, action = {}) => {
  switch (action.type) {
    case types.UPDATE_SCREEN_WIDTH:
      return action.payload
    default:
      return state
  }
}

const homeTab = (state = {}, action = {}) => {
  switch (action.type) {
    case types.SET_TAB_MODE:
      return {
        ...state,
        tab: action.payload
      }
    default:
      return state
  }
}

const isTouch = (state = {}) => state

export default combineReducers({
  flyout,
  screenType,
  navPanel,
  filters,
  location,
  loading,
  isTouch,
  mainMenu,
  homeTab,
  favourites
})
