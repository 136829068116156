import { GOOGLE_CLICK_REF } from 'Constants'
import { getUrlParameter } from './getUrlParameter'

function hasSessionstorage() {
  let mod = 'test'
  try {
    window.sessionStorage.setItem(mod, mod)
    window.sessionStorage.removeItem(mod)
    return true
  } catch (e) {
    console.log('sessionStorage unavailable!')
    return false
  }
}

export const storeClickref = location => {
  const isClient = typeof window !== 'undefined'
  const googleRef = getUrlParameter({ name: GOOGLE_CLICK_REF, location })
  if (!googleRef || !isClient) return null
  if (!hasSessionstorage()) return null

  window.sessionStorage.setItem(GOOGLE_CLICK_REF, googleRef)
}

export const replaceClickref = (affiliateUrl, location) => {
  const isClient = typeof window !== 'undefined'
  const ANT_CLICK_REF =
    process.env.ANT_ENVIRONMENT === 'local'
      ? 'qvhvmu'
      : process.env.ANT_CLICK_REF

  // on the server look in the url for a google click ref
  if (!isClient) {
    let googleRefFromRouter
    if (location) {
      googleRefFromRouter = getUrlParameter({
        name: GOOGLE_CLICK_REF,
        location
      })
    }
    return googleRefFromRouter
      ? affiliateUrl.replace(ANT_CLICK_REF, googleRefFromRouter)
      : affiliateUrl
  }

  // else client, look in sessionStorage
  if (!hasSessionstorage()) return affiliateUrl

  const googleRef = window.sessionStorage.getItem(GOOGLE_CLICK_REF)

  return ANT_CLICK_REF && googleRef
    ? affiliateUrl.replace(ANT_CLICK_REF, googleRef)
    : affiliateUrl
}
