import { css } from '@emotion/core'
import theme from 'Theme'

const { colors, zIndex } = theme

export default () => css`
  .site-wide {
    background-color: ${colors.bodyBackground};
    border-bottom: 1px solid ${colors.borderGrey};
    border-top: 1px solid ${colors.borderGrey};

    /* required for nav-panel-overlay positioning */
    z-index: ${zIndex.siteWide};
    position: relative;
  }
`
