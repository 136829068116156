import React from 'react'

export const ImageIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    viewBox="0 0 60 60"
  >
    <title>photo</title>
    <path d="M0 12c0-3.3 2.7-6 6-6h48c3.314 0 6 2.686 6 6v0 36c0 3.314-2.686 6-6 6v0h-48c-3.314 0-6-2.686-6-6v0-36zM33 39l-9-9-18 18h48l-15-15-6 6zM45 27c3.314 0 6-2.686 6-6s-2.686-6-6-6v0c-3.314 0-6 2.686-6 6s2.686 6 6 6v0z"></path>
  </svg>
)
