import { UrlBuilder, gaReady, gtagReady } from 'Utils'
import { gaSettings } from 'Config'

const setPrice = (min, history, queryString, price) => {
  const newQuery = new UrlBuilder(queryString)
  if (min) {
    newQuery.addMinPrice(price)
  } else {
    newQuery.addMaxPrice(price)
  }

  newQuery.setPage(1)
  history.push(newQuery.getQueryString())
}

const { PriceFilter: filterSettings } = gaSettings.productList.filters
const SendGA = ( eventLabel, additionalProps ) => {
  const GAEvent = {
    hitType: 'event',
    eventCategory: filterSettings.category,
    eventAction: filterSettings.action,
  }
  if (gaReady()) {
    /* eslint-disable no-undef */
    ga('send', { ...GAEvent, ...eventLabel })
    ga(`${process.env.ANT_GA_CLIENT_TRACKER_NAME}.send`, {
      ...GAEvent,
      ...eventLabel
    })
    /* eslint-enable no-undef */
  }
  if (gtagReady) {
    /* eslint-disable no-undef */
      gtag(
    /* eslint-enable no-undef */
        'event',
        filterSettings.action,
        {
          tracker: `${process.env.ANT_GA_CLIENT_TRACKER_NAME}.send`,
          category: filterSettings.category,
          ...additionalProps,
        }
      )
  }
}

export { setPrice, SendGA }
