import { PAGE_CONTEXT as p } from 'Constants'
import spacing from './spacing'

export const layout = {
  maxWidth: {
    mainContainer: 1300,
    page: 1300,
    article: 735,
    card: 600,
    productOverview: 960,
    trendImage: 1024
  },
  navPanel: {
    height: 443
  },
  gutterSize: {
    small: spacing.base_1,
    medium: spacing.base_3,
    large: spacing.base_3,
    fallback: spacing.base_3,
    home: {
      small: spacing.base_3,
      large: spacing.base_3,
      fallback: spacing.base_3
    },
    homeSmall: {
      small: spacing.base_2,
      large: spacing.base_3,
      fallback: spacing.base_3
    }
  },
  itemsPerRow: {
    altProduct: { small: 2, fallback: 4 },
    article: { small: 2, fallback: 4 },
    [p.ARTICLE_LIST]: { small: 2, fallback: 4 },
    trend: { small: 2, fallback: 4 },
    [p.TREND_LIST]: { small: 1, medium: 3, fallback: 4 },
    [p.INSTALOOK_LIST]: { small: 1, medium: 3, fallback: 4 },
    compare: { small: 2, fallback: 4 },
    homeArticles: { small: 2, medium: 3, fallback: 4 },
    productList: { small: 2, medium: 3, large: 4, fallback: 4 },
    relArticles: { fallback: 1 },
    stores: { small: 2, medium: 4, fallback: 4 },
    [p.ARTICLE_MODAL]: { small: 1, medium: 2, fallback: 4 },
    embed: { small: 2, medium: 4, large: 4, fallback: 4 },
    [p.EMBED_MODAL]: { small: 2, medium: 4, large: 4, fallback: 4 },
    homeInstalooks: { small: 1, medium: 2, fallback: 4 },
    default: { small: 1, medium: 2, fallback: 4 }
  },
  limits: {
    homeArticles: { small: 4, medium: 3, large: 4, fallback: 4 },
    homeTrends: { small: 4, medium: 3, large: 4, fallback: 4 },
    fallback: { medium: 3, fallback: 4 }
  },
  articlePage: {
    gridGuide: {
      null: { small: 2, medium: 4, fallback: 4 },
      4: { small: 2, medium: 4, fallback: 4 },
      2: { small: 1, medium: 2, fallback: 2 },
      1: { small: 1, medium: 1, fallback: 1 },
      default: { small: 2, medium: 4, fallback: 4 }
    },
    productCollectionImageWidths: {
      1: 350,
      2: 250,
      4: 150
    }
  }
}

export default layout
