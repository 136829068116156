import { css } from '@emotion/core'

export default css`
  position: relative;
  padding: 0;
  margin: 0;
  min-width: 50px;

  .dropdown {
    width: 100%;
    padding: 0.25rem;
    appearance: none;
    border-radius: 0;
  }
`
