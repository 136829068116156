export const typography = {
  headings: {
    lineHeight: '1.3em',
    fontWeight: '400',
    fontFamily: 'Helvetica, Arial, sans-serif',
    subHeadLetterSpacing: '.1em'
  },
  body: {
    fontFamily: 'Helvetica, Arial, sans-serif',
    lineHeight: '1em'
  },
  sizes: {
    xsmall: '0.6rem',
    msmall: '0.8rem',
    small: '0.8rem',
    large: '1.2rem',
    larger: '1.5rem',
    xlarge: '2.5rem',
    xxlarge: '3rem',
    trendtitle: '35px',
    compareLinkFont: '42px'
  },
  baseFontSize: '16px'
}
export default typography
