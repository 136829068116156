import { css } from '@emotion/core'
import { mediumOnly, largeUp } from 'Utils'
import theme from 'Theme'
const { layout, colors, typography, mixins } = theme

export default () => css`
  &.content-panel {
    display: block;
    min-height: 380px;
    position: relative;
    z-index: 1;

    &:hover {
      .content-panel__img {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  .content-panel__img {
    height: 330px;
    width: 100%;
    position: relative;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.25);
      opacity: 0;
    }
  }

  .content-panel__title {
    background-color: white;
    text-align: center;
    width: 80%;
    padding: 10px 30px;
    min-height: 20%;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translateX(-50%);

    z-index: 2;

    h2 {
      font-size: 1rem;
    }

    span {
      display: inline-block;
    }
  }
`
