import React from 'react'
import config from 'Config'
import get from 'lodash/get'

export const metaTitleGenerator = (location, contents) => {
  let result = config.helmet.metaTitleFormat[location]
  result = result.replace(/\{\{PARTNER_NAME\}\}/, config.helmet.partner)

  if (contents) {
    Object.keys(contents).forEach(key => {
      result = result.replace(new RegExp(`{{${key}}}`), contents[key])
    })
  }
  return result
}

export const metaNoindexGenerator = (pageName, storeName) => {
  const metaTag = <meta name="robots" content="noindex" />
  const noIndexRules = config.helmet.noIndex
  const pageRule = get(noIndexRules, pageName, false)

  // noindex all
  if (noIndexRules.all) {
    return metaTag
  }

  // by store name
  if (Array.isArray(pageRule) && storeName) {
    let lowerCaseStores = []
    pageRule.map(store => lowerCaseStores.push(store.toLowerCase()))
    return lowerCaseStores.includes(storeName.toLowerCase()) ? null : metaTag
  }

  // by page name
  return pageRule ? metaTag : null
}
