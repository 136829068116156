import PropTypes from 'prop-types'
import React from 'react'
import { PAGE_CONTEXT as p } from 'Constants'
import { PageHelmet } from 'Blocks'

const NoMatch = ({ staticContext }) => {
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '400px'
  }

  if (staticContext) {
    staticContext.status = 404
  }

  const pageName = p.NOT_FOUND

  return (
    <div className="main-container" {...{ style }}>
      <PageHelmet
        pageName={pageName}
        description={`Either something went wrong or the page doesn't exist anymore.`}
      />
      <div style={{ textAlign: 'center', lineHeight: '2.5rem' }}>
        <h1 style={{ fontSize: '2.5rem' }}>404</h1>
        <h2 style={{ fontSize: '1.5rem' }}>Oops, page not found.</h2>
        <h3>{`Either something went wrong or the page doesn't exist anymore.`}</h3>
      </div>
    </div>
  )
}

NoMatch.propTypes = {
  staticContext: PropTypes.shape({
    status: PropTypes.any
  })
}

export default NoMatch
