import { colors } from '../colors'
import { typography } from '../typography'

export const icon = {
  fill: colors.primary,
  height: typography.sizes.small,
  width: typography.sizes.small,
  size: {
    small: typography.sizes.small
  }
}
export default icon
