import { Component } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    const urlHasChanged = this.props.location !== prevProps.location
    const previousPathName = prevProps.location.pathname
    const newPathName = this.props.location.pathname

    const fromArticleToHomeArticleList =
      previousPathName.indexOf('/article/') > -1 &&
      (newPathName === `${process.env.ANT_PREFIX}` ||
        newPathName === `${process.env.ANT_PREFIX}/`)

    const fromProductToProductlist =
      previousPathName.indexOf('/product/') > -1 &&
      newPathName.indexOf('/products/') > -1

    const fromTrendToTrendlist =
      previousPathName.indexOf('/trend/') > -1 &&
      newPathName.indexOf('/trends') > -1

    const fromArticleToArticlelist =
      previousPathName.indexOf('/article/') > -1 &&
      newPathName.indexOf('/articles') > -1

    const fromInstalookToInstalooklist =
      previousPathName.indexOf('/instalook/') > -1 &&
      newPathName.indexOf('/instalooks') > -1

    const fromContentToList = !!(
      fromProductToProductlist ||
      fromTrendToTrendlist ||
      fromArticleToArticlelist ||
      fromInstalookToInstalooklist ||
      fromArticleToHomeArticleList
    )

    if (urlHasChanged && !fromContentToList) {
      setTimeout(() => window.scrollTo(0, 0), 1)
    }
  }

  render() {
    return this.props.children
  }
}

ScrollToTop.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  children: PropTypes.node
}

export default withRouter(ScrollToTop)
