import { connect } from 'react-redux'
import component from './component'
import { skin, UrlBuilder } from 'Utils'
import skins from './skins'

const mapStateToProps = (state, props) => {
  const queryString = props.location.search
  const screenType = state.UI.screenType

  const currentCategory = new UrlBuilder(queryString).getCategory()

  return {
    currentCategory,
    queryString,
    screenType
  }
}

const mappedComponent = connect(mapStateToProps, null)(component)

export default skin(mappedComponent, skins)
