import { css } from '@emotion/core'
import { largeUp } from 'Utils'
import theme from 'Theme'

const { mixins, colors, spacing, typography } = theme

export default () => {
  return css`
    max-width: 960px;
    margin: ${spacing.vertical.medium} auto 0 auto;
    @media ${largeUp} {
      margin: ${spacing.vertical.large} auto 0 auto;
    }

    .items-found {
      margin-bottom: ${spacing.vertical.small};
      height: 20px;
      &.loading {
        width: 50px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .items-found__label {
      text-align: center;
      font-size: ${typography.sizes.small};
    }

    .panel-filters__filters {
      display: flex;
    }

    .panel-location-title {
      text-align: center;
      text-transform: uppercase;
      margin-bottom: ${spacing.vertical.small};
      font-size: ${typography.sizes.larger};
    }

    .panel-filters__title {
      color: ${colors.secondary};
      font-weight: normal;
      display: block;
      font-size: ${typography.baseFontSize};
      line-height: 16px;
      padding: 8px;
    }

    .panel {
      width: 33.3%;
      height: 332px;
      padding: 0 1rem;
      ${mixins.transparencyGradient()}
      &.panel-filters__sort {
        border-right: 1px solid ${colors.borderGreyLight};

        .title {
          display: none;
        }
      }

      &.panel-filters__price {
        border-right: 1px solid ${colors.borderGreyLight};
      }
    }
  `
}
