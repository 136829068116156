import config from 'Config'
import get from 'lodash/get'
import { gaReady, gtagReady } from './withGAEvent'

export const sendTrackingEvent = ({ amplitude, gaEvent, additionalProps }) => {
  const event = {
    hitType: 'event',
    eventCategory: get(gaEvent, 'category', ''),
    eventAction: get(gaEvent, 'action', ''),
    eventLabel: get(gaEvent, 'label', '')
  }
  const isWindowAmplitude =
    typeof window !== 'undefined' && window.amplitude !== 'undefined'
  if (config.amplitude.enabled && isWindowAmplitude) {
    const eventType = get(amplitude, 'eventType', false)
    const eventProps = get(amplitude, 'eventProps', false)
    if (eventType && eventProps) {
      /* eslint-disable no-undef */
      window.amplitude.getInstance().logEvent(eventType, eventProps)
      /* eslint-enable no-undef */
    }
  }
  if (gaReady()) {
    /* eslint-disable no-undef */
    ga('send', event)
    ga(`${process.env.ANT_GA_CLIENT_TRACKER_NAME}.send`, event)
    /* eslint-enable no-undef */
  }
  if (gtagReady()) {
    // eslint-disable-next-line no-undef
    gtag(
      'event',
      event.eventAction,
      {
        category: event.eventCategory,
        tracker: `${process.env.ANT_GA_CLIENT_TRACKER_NAME}.send`,
        ...additionalProps,
      }
    )
  }
}
