import { TITLE_LIMIT } from 'Constants'

export const cutTitleToLimit = (title, limit) => {
  let titleCut = title
  const cutterLimit = Number.isInteger(limit) ? limit : TITLE_LIMIT
  if (title.length > cutterLimit) {
    const indexOfLastSpace = title.slice(0, cutterLimit).lastIndexOf(' ')
    titleCut = `${title.slice(0, indexOfLastSpace)}...`
  }
  return titleCut
}
