import { colors } from '../colors'
import { typography } from '../typography'

export const panelCard = {
  introLinkColor: colors.active,
  fontFamily: typography.headings.fontFamily,
  contentWidth: '50%',
  align: 'left',
  color: colors.primary,
  hoverColor: colors.complementary.primary,
  contentAlign: 'center',
  overlayBgColor: 'rgba(0,0,0,.5)',
  contentStyle: 'italic',
  titleFontSize: {
    small: '.9rem',
    large: '1.2rem'
  },
  introFontSize: {
    small: '.7rem',
    large: '1rem'
  },
  contentPadding: {
    small: '.3rem 1rem .3rem 1rem',
    large: '1rem 3rem 1rem 3rem'
  }
}

export default panelCard
