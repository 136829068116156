import { typography } from '../typography'
import { colors } from '../colors'
import { layout } from '../layout'

export const productOverview = {
  mobileTextAlign: 'center',
  overviewPadding: {
    large: '1rem 0'
  },
  maxWidth: layout.maxWidth.page,
  image: {
    justify: 'center'
  },
  brand: {
    color: colors.secondary,
    spacing: '.5rem',
    textTransform: 'uppercase',
    letterSpacing: 'inherit',
    font: {
      family: typography.headings.fontFamily,
      weight: 'normal',
      size: typography.baseFontSize
    }
  },
  title: {
    maxWidth: 'inherit',
    color: colors.primary,
    spacing: '1.5rem',
    textTransform: 'inherit',
    font: {
      family: typography.headings.fontFamily,
      weight: 'normal',
      size: {
        small: typography.sizes.large,
        large: typography.sizes.larger
      }
    }
  },
  price: {
    color: colors.active,
    spacing: '1.5rem',
    font: {
      family: typography.headings.fontFamily,
      size: typography.sizes.larger,
      weight: 'bold'
    },
    original: {
      font: {
        family: typography.headings.fontFamily,
        weight: 'normal',
        size: typography.sizes.large,
        style: 'italic',
        textDecoration: 'line-through'
      }
    }
  },
  buyButton: {
    color: colors.complementary.primary,
    backgroundColor: colors.primary,
    textTransform: 'uppercase',
    letterSpacing: 'inherit',
    spacing: '1rem',
    padding: '.5rem 2rem',
    border: {
      style: '1px solid',
      color: colors.primary,
      radius: 'initial'
    },
    font: {
      family: typography.headings.fontFamily,
      size: typography.baseFontSize,
      weight: 'normal'
    },
    hover: {
      color: colors.complementary.primary,
      backgroundColor: colors.primary,
      borderColor: colors.primary
    }
  },
  description: {
    maxWidth: 'none',
    color: colors.active,
    spacing: '.5rem',
    label: {
      color: colors.active,
      spacing: '.5rem',
      font: {
        family: typography.headings.fontFamily,
        size: typography.baseFontSize,
        weight: 'bold'
      }
    }
  }
}

export default productOverview
