import PropTypes from 'prop-types'
import React from 'react'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { formatNumber } from 'Utils'
import config from 'Config'

const LoadingUI = () => (
  <div className="spinner">
    <div className="bounce1"></div>
    <div className="bounce2"></div>
    <div className="bounce3"></div>
  </div>
)

const ItemsFoundContainer = props => {
  const { count, loading } = props
  const loadingclass = loading ? 'loading' : ''
  return (
    <div className={`${props.className} items-found ${loadingclass}`}>
      {loading ? (
        <LoadingUI />
      ) : (
        <span className="items-found__label">
          {`${formatNumber(count)} ${
            config.locale === 'de' ? 'Artikel gefunden' : 'items found'
          }`}
        </span>
      )}
    </div>
  )
}

ItemsFoundContainer.propTypes = {
  className: PropTypes.any,
  count: PropTypes.any,
  loading: PropTypes.any
}

const mapStateToProps = state => ({
  count: get(state, 'UI.filters.products.aggregations.count', '')
})

export default connect(mapStateToProps, null)(ItemsFoundContainer)
