import React from 'react'
import { string, object, func } from 'prop-types'

const Icon = ({
  title = '',
  name = '',
  className = '',
  style = {},
  onClick = () => {}
}) => (
  <svg {...{ style, className, name, onClick }}>
    {title.length > 0 && <title>{title}</title>}
    <use xlinkHref={`#icon-${name}`} />
  </svg>
)

Icon.propTypes = {
  className: string,
  name: string,
  onClick: func,
  style: object,
  title: string
}

export default Icon
