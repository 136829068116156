import { css } from '@emotion/core'
import { mediumUp } from 'Utils'
import theme from 'Theme'

const { colors, spacing, zIndex } = theme

export default () => css`
  position: fixed;
  z-index: ${zIndex.toTopButton};
  background-color: black;
  width: 50px;
  height: 50px;
  top: 80vh;
  right: 2vw;

  svg {
    fill: white;
  }

  &.enter {
    opacity: 0;
  }
  &.enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  &.exit {
    opacity: 1;
  }
  &.exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }

  @media ${mediumUp} {
    &:hover {
      background-color: ${colors.darkGrey};
    }
  }
`
