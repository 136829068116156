import { gql } from '@apollo/client'
import categoryFragment from './category'

const smallContentFragment = /* GraphQL */ gql`
  fragment smallContent on Content {
    type
    uuid
    slug
    sponsor
    theme
    created
    image {
      main
      cropped
    }
    categories {
      ...categoryFields
    }
    author {
      name
    }
    flags {
      isFeatured
    }
    layout {
      images {
        data {
          link
          credit
          url
        }
        position
      }
    }
    state {
      code
    }
    title {
      long
      short
    }
  }

  ${categoryFragment}
`

export default smallContentFragment
