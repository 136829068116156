import { ACTION_TYPES as types } from 'Constants'

export default (state = { search: '' }, action = {}) => {
  switch (action.type) {
    case types.STORE_LOCATION:
      return action.payload

    default:
      return state
  }
}
