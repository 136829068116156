export const zIndex = {
  panelOverlay: 2,
  panelContent: 3,
  backdrop: 4,
  dialogContent: 5,
  dialog: 1001,
  searchBox: 1,
  filterPanel: {
    panel: 1,
    menu: 2,
    activePanel: 10,
    closeDialog: 4
  },
  siteWide: 4
}
export default zIndex
