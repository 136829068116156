import { css } from '@emotion/core'
import { mediumUp } from '../../utils/responsive'

export const twoColumnLayout = css`
  /** spinner **/
  .product-list-wrapper {
    position: relative;
    min-height: 50vh;
  }

  .spinner {
    position: absolute;
  }

  /*** layout helper classes ***/
  .row {
    margin-bottom: 2rem;
  }

  /*** coupled to this DOM structure:

    .main-container
      .breadcrumbs
      .heading
      .sorting
      .filters-wrapper
        .filters
      .product-list-wrapper
        .spinner
        .product-list
        .pagination


    .main-container.product-not-found
      .breadcrumbs
      .heading
      .product-not-found

  ***/

  /*** FALLBACK - START ***/

  &:after {
    content: '';
    clear: both;
    display: table;
    height: 0;
  }

  @media ${mediumUp} {
    .filters-wrapper {
      float: left;
      width: 25%;
    }

    .product-list-wrapper {
      float: left;
      width: 72.5%;
      margin-left: 2.5%;
    }
  }

  /*** FALLBACK - END ***/

  /*** CLEAN UP - START - BECAUSE THE FALLBACK ***/

  @media ${mediumUp} {
    @supports (display: grid) {
      .product-list-wrapper {
        float: none;
        width: auto;
        max-width: none;
        margin-left: auto;
      }

      .product {
        float: none;
        max-width: none;
      }

      .filters-wrapper {
        float: none;
        width: auto;
      }
    }
  }

  /*** CLEAN UP - END - BECAUSE THE FALLBACK ***/

  @supports (display: grid) {
    .breadcrumbs {
      grid-area: breadcrumbs;
    }

    .heading {
      grid-area: heading;
    }

    .category-slider {
      grid-area: category-slider;
    }

    .filters-wrapper {
      grid-area: filters;
    }

    .product-list-wrapper {
      grid-area: product-list;
      width: 100%;
    }

    .pagination {
      grid-area: pagination;
    }

    .sorting {
      grid-area: sorting;
    }

    @media ${mediumUp} {
      display: grid;
      grid-template-areas:
        'breadcrumbs breadcrumbs breadcrumbs breadcrumbs'
        'heading heading sorting sorting'
        'filters product-list product-list product-list';
      grid-template-columns: 300px 1fr 1fr 1fr;
      grid-gap: 1rem;
    }
  }

  .no-product-wrapper {
    grid-column-start: product-list-start;
    grid-column-end: product-list-end;
  }

  .no-product-label {
    @media ${mediumUp} {
      font-size: 2.5rem;
    }
    font-size: 1.5rem;
  }

  /* Product not found template */

  &.product-not-found {
    @supports (display: grid) {
      .product-not-found-content {
        grid-area: product-not-found-content;
      }

      @media ${mediumUp} {
        display: grid;
        grid-template-areas:
          'breadcrumbs'
          'heading'
          'product-not-found-content';
        grid-template-columns: 1fr;
        grid-gap: 1rem;
      }
    }
  }
`

export default twoColumnLayout
