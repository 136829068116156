import components from './components'
import colors from './colors'
import twoColumnLayout from './twoColumnLayout'
import spacing from './spacing'
import layout from './layout'
import reset from './reset'
import mixins from './mixins'
import zIndex from './zIndex'
import typography from './typography'
import { elements } from './elements'

const elementColors = {
  text: colors.primary,
  menu: {
    background: colors.complementary.primary,
    link: colors.primary
  },
  select: {
    downArrow: colors.primary
  }
}

const theme = {
  elements,
  colors,
  twoColumnLayout,
  layout,
  reset,
  mixins,
  spacing,
  zIndex,
  elementColors,
  typography,
  components: {
    panel: {
      color: colors.primary,
      hoverColor: colors.complementary.primary
    },
    ...components
  }
}

export default theme
