export const colors = {
  bodyBackground: '#F2F2F2',
  body: '#666',
  disabled: '#D4D4D4',
  mobileBorderLine: '#DADADA',
  active: '#000',
  primary: '#000',
  secondary: '#CCC',
  tertiary: '#fafafa',
  discount: '#c9003c',
  discountedPrice: '#909090',
  alt1: '#42b7f8',
  white: '#fff',
  black: '#000',
  oos: 'C40820',
  borderGrey: '#979797',
  borderGreyLight: '#E1E1E1',
  introGrey: '#868287',
  complementary: {
    primary: '#FFF',
    secondary: '#666',
    tertiary: '#909090',
    alt1: '#205978'
  },
  mainNav: {
    active: 'rgba(98, 79, 135, 0.41)',
    current: '#625087',
    complementary: {
      active: '#FFF',
      current: '#FFF'
    }
  },
  boxShadow: '#CCC',
  imageTint: 'rgba(0, 0, 0, 0.25);',
  toolbar: {
    buttonColor: '#CCC',
    textColor: '#FFF',
    backgroundColor: '#205978',
    backgroundColorDark: '#0F2A39'
  },
  article: {
    delete: 'red',
    status: {
      DRAFT: 'grey',
      AWAITING_REVIEW: 'orange',
      PUBLISHED: 'green'
    },
    item: {
      title: '#000'
    }
  },
  brand: {
    primary: '#0A3E71'
  },
  articleItem: {
    button: {
      color: 'white',
      backgroundColor: 'black',
      hover: {
        color: 'black',
        backgroundColor: 'white'
      }
    },
    meta: {
      color: 'black',
      fontWeight: 'bold'
    },
    headings: {
      color: 'black'
    }
  },
  loading: {
    color: 'rgba(0,0,0, 0.4)',
    panel: {
      light: {
        backgroundColor: '#F5F5F5',
        animationColor: '#FFF'
      },
      dark: {
        backgroundColor: '#F5F5F5',
        animationColor: '#000'
      }
    },
    button: {
      hover: {
        backgroundColor: '#7F7F7F'
      }
    }
  },
  carousel: {
    dots: {
      active: '#000',
      color: '#9B9B9B'
    }
  },
  progress: {
    backgroundColor: '#D8D8D8',
    color: '#000'
  },
  button: {
    disabled: '#9a9a9a'
  }
}

export default colors
