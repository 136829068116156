import { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { updateScreenType } from 'Actions'
import { SCREEN_TYPE } from 'Constants'
import {
  shouldRenderSmall,
  shouldRenderMedium,
  shouldRenderLarge,
  shouldRenderXL,
  shouldRenderXXL
} from '../utils/responsive'

class ResponsiveProvider extends Component {
  constructor(props) {
    super(props)

    this.state = { screenType: props.screenType }
    this.onResize = this.onResize.bind(this)
  }

  componentDidMount() {
    this.updateScreenWidth()

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.onResize, false)
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize)
    }
  }

  updateScreenWidth() {
    if (typeof window.innerWidth !== 'undefined') {
      let screenType
      let width = window.innerWidth

      switch (true) {
        case shouldRenderSmall(width): {
          screenType = SCREEN_TYPE.SMALL
          break
        }
        case shouldRenderMedium(width): {
          screenType = SCREEN_TYPE.MEDIUM
          break
        }
        case shouldRenderLarge(width): {
          screenType = SCREEN_TYPE.LARGE
          break
        }
        case shouldRenderXL(width): {
          screenType = SCREEN_TYPE.XL
          break
        }
        case shouldRenderXXL(width): {
          screenType = SCREEN_TYPE.XXL
          break
        }
        default: {
          screenType = null
        }
      }

      if (screenType !== this.state.screenType) {
        this.props.updateScreenType(screenType)
      }
      this.setState({ screenType })
    }
  }

  onResize() {
    if (this.rqf) {
      return
    }
    if (typeof window !== 'undefined') {
      this.rqf = window.requestAnimationFrame(() => {
        this.rqf = null
        this.updateScreenWidth()
      })
    }
  }

  render() {
    return this.props.children
  }
}

const mapStateToProps = state => ({
  screenType: get(state, 'UI.screenType', '')
})

const mapDispatchToProps = dispatch => {
  return {
    updateScreenType: bindActionCreators(updateScreenType, dispatch),
    dispatch
  }
}

ResponsiveProvider.propTypes = {
  screenType: PropTypes.string,
  updateScreenType: PropTypes.func,
  children: PropTypes.node
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveProvider)
