import Component from './component'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { skin } from 'Utils'
import { setPriceFilters } from 'Actions'
import skins from './skins'

const mapStateToProps = state => ({
  price_filters: get(state, 'UI.filters.price', {})
})

const mapDispatchToProps = dispatch => ({
  setPriceFilters: bindActionCreators(setPriceFilters, dispatch),
  dispatch
})

export default skin(
  connect(mapStateToProps, mapDispatchToProps)(Component),
  skins
)
