import { typography } from '../typography'
import { colors } from '../colors'

export const productOverview = {
  overviewPadding: {
    large: '3rem 2rem'
  },
  maxWidth: '1080px',
  brand: {
    color: colors.body,
    textTransform: 'inherit',
    font: {
      family: typography.body.fontFamily,
      size: typography.sizes.large
    }
  },
  title: {
    color: colors.primary,
    font: {
      family: typography.headings.fontFamily,
      size: {
        small: typography.sizes.large,
        large: typography.sizes.large
      }
    }
  },
  price: {
    color: colors.active,
    spacing: '1.5rem',
    font: {
      family: typography.headings.fontFamily,
      size: '20px',
      weight: 'normal'
    },
    original: {
      font: {
        weight: 'normal',
        size: '20px',
        style: 'normal',
        textDecoration: 'line-through'
      }
    },
    discounted: {
      font: {
        weight: 'normal',
        size: '30px',
        style: 'normal'
      }
    }
  },
  buyButton: {
    color: colors.complementary.primary,
    backgroundColor: colors.active,
    textTransform: 'inherit',
    letterSpacing: 'inherit',
    spacing: '1rem',
    padding: '.5rem 2rem',
    border: {
      style: '1px solid',
      color: colors.active,
      radius: '4px'
    },
    font: {
      family: typography.headings.fontFamily,
      size: '24px',
      weight: 'normal'
    },
    hover: {
      color: colors.active,
      backgroundColor: colors.complementary.primary,
      borderColor: colors.active
    }
  },
  description: {
    maxWidth: 'none',
    color: colors.active,
    spacing: '.5rem',
    label: {
      color: colors.active,
      spacing: '.5rem',
      font: {
        family: typography.headings.fontFamily,
        size: typography.baseFontSize,
        weight: 'bold'
      }
    }
  }
}

export default productOverview
