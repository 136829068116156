import PropTypes from 'prop-types'
import React from 'react'
import { contentPropTypes } from 'Types'
import get from 'lodash/get'
import { path, cutTitleToLimit, sendTrackingEvent } from 'Utils'
import { Link } from 'Blocks'
import { gaSettings } from 'Config'

const { menu } = gaSettings

const ContentPanel = props => {
  const {
    position,
    content: { uuid, slug, image, type, title }
  } = props
  const imageUrl = get(image, 'main', '')
  const cardTo = path(type, { uuid, slug })
  const displayTitle = title.short
    ? cutTitleToLimit(title.short, 20)
    : cutTitleToLimit(title.long, 20)
  const gaEvent = {
    category: menu.category,
    action: menu.action,
    label: `linkName=${displayTitle} - destinationUrl=${cardTo} - imagePanel=${position}`
  }
  return (
    <Link
      className={`${props.className} content-panel`}
      to={cardTo}
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        sendTrackingEvent({ amplitude: {}, gaEvent, additionalProps: {
          linkName: displayTitle,
          destinationUrl: cardTo,
        }})
        props.closeNavPanel()
      }}
    >
      <div
        className="content-panel__img"
        style={{
          backgroundImage: `url('${imageUrl}')`,
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      ></div>
      <div className="content-panel__title">
        <h2>{displayTitle}</h2>
        <span>Read story</span>
      </div>
    </Link>
  )
}

ContentPanel.propTypes = {
  className: PropTypes.string,
  closeNavPanel: PropTypes.func,
  position: PropTypes.any,
  content: PropTypes.shape(contentPropTypes)
}

export default ContentPanel
