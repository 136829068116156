export const article = {
  ArticleProductCard: {
    all: {
      action: 'Outgoing'
    },
    alternativeImage: {
      category: 'Article - Main Product - $1 - Compare Image',
      action: 'Navigation'
    },
    alternativeText: {
      category: 'Article - Main Product - $1 - Compare Text',
      action: 'Navigation'
    }
  },
  ArticleItem: {
    itemImage: {
      category: 'Article - $1 Featured Product - Image',
      action: 'Outgoing'
    },
    itemText: {
      category: 'Article - Featured Product - Text',
      action: 'Navigation'
    },
    itemButton: {
      category: 'Article - $1 Featured Product - Button',
      action: 'Outgoing'
    },
    itemCompare: {
      category: 'Article - Featured Product - $1 - Compare More',
      action: 'Navigation'
    }
  },
  RelatedArticlesList: {
    relatedArticleLink: {
      category: 'Article - Related Article',
      action: 'Navigation'
    }
  },
  ContentCard: {
    category: '$pageName - Content link',
    action: 'Navigation'
  },
  heroImage: {
    category: 'articleImage',
    action: 'Outgoing'
  },
  customButton: {
    category: 'Article Custom Button',
    action: 'Outgoing'
  },
  linkInText: {
    category: 'Article text link',
    action: 'Outgoing'
  }
}

export const hub = {
  ArticleProductCard: {
    alternativeImage: {
      category: 'Article - Main Product - $1 - Compare Image',
      action: 'Navigation'
    },
    alternativeText: {
      category: 'Article - Main Product - $1 - Compare Text',
      action: 'Navigation'
    }
  },
  ArticleItem: {
    itemImage: {
      category: 'Article - Main Product - Image',
      action: 'Navigation'
    },
    itemText: {
      category: 'Article - Main Product - Text',
      action: 'Navigation'
    },
    itemCompare: {
      category: 'Article - Main Product - $1 - Compare More',
      action: 'Navigation'
    }
  },
  RelatedArticlesList: {
    relatedArticleLink: {
      category: 'Article - Related Article',
      action: 'Navigation'
    }
  },
  ContentCard: {
    category: 'Article List - Article Link',
    action: 'Navigation'
  }
}

export const trend = {
  TrendProductCard: {
    alternativeImage: {
      category: 'Trend - Main Product - $1 - Compare Image',
      action: 'Navigation'
    },
    alternativeText: {
      category: 'Trend - Main Product - $1 - Compare Text',
      action: 'Navigation'
    }
  },
  TrendItem: {
    itemImage: {
      category: 'Trend - Main Product - Image',
      action: 'Navigation'
    },
    itemText: {
      category: 'Trend - Main Product - Text',
      action: 'Navigation'
    },
    itemCompare: {
      category: 'Trend - Main Product - $1 - Compare More',
      action: 'Navigation'
    }
  },
  RelatedTrendsList: {
    relatedTrendLink: {
      category: 'Trend - Related Article',
      action: 'Navigation'
    }
  },
  ContentCard: {
    category: 'Trend List - Trend Link',
    action: 'Navigation'
  }
}

export const productList = {
  subCategoryMenu: {
    category: '$1 - subCategoryLink',
    action: 'Navigation'
  },
  ProductCard: {
    image: {
      category: '$1 - Product card',
      action: 'Outgoing'
    },
    text: {
      category: '$1 - Product card',
      action: 'Navigation'
    }
  },
  filters: {
    PriceFilter: {
      category: 'refineProducts - priceRange',
      action: 'Filter'
    },
    StoreListFilter: {
      category: 'refineProducts - storeFilter',
      action: 'Filter'
    },
    ColorListFilter: {
      category: 'refineProducts - colorFilter',
      action: 'Filter'
    },
    SaleFilter: {
      category: 'refineProducts - onSale',
      action: 'Filter'
    }
  }
}

export const breadcrumbs = {
  category: '$1 - breadcrumbLink',
  action: 'Navigation'
}

export const homePage = {
  contentCards: {
    category: 'Homepage - $1 - Links',
    action: 'Navigation'
  },
  moreLinks: {
    category: 'Homepage - More $1',
    action: 'Navigation'
  },
  categorylinks: {
    category: 'Homepage - Category Links',
    action: 'Navigation'
  },
  retailerLinks: {
    category: 'Homepage - External Links',
    action: 'Outgoing'
  }
}

export const menu = {
  category: 'Menu Links',
  action: 'Navigation'
}

// TODO: could be deleted
export const contentCard = {
  category: '$pageName - $type - Content card',
  action: 'Navigation'
}

export const productPage = {
  mainProduct: {
    buyButton: {
      category: 'mainProduct - buyButton',
      action: 'Outgoing'
    },
    favouriteButton: {
      category: 'mainProduct - favouriteButton',
      action: 'Navigation'
    }
  },
  productCompare: {
    image: {
      category: 'mainProduct - compareImage',
      action: 'Outgoing'
    },
    text: {
      category: 'mainProduct - compareText',
      action: 'Navigation'
    },
    compareButton: {
      category: 'mainProduct - compareMore',
      action: 'Navigation'
    }
  }
}

const gaSettings = {
  hub,
  article,
  trend,
  breadcrumbs,
  productList,
  productPage,
  homePage,
  menu,
  contentCard
}

export default gaSettings
