import styled from '@emotion/styled'
import config from 'Config'

export const skin = (Component, skins, secondarySkins) => {
  const appliedSkins = skins[config.name] || skins[config.group] || skins.plain
  const secondAppliedSkins = secondarySkins
    ? secondarySkins[config.name] ||
      secondarySkins[config.group] ||
      secondarySkins.plain
    : ''
  return styled(Component)`
    ${appliedSkins} ${secondAppliedSkins}
  `
}
