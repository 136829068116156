import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  metaTitleGenerator,
  metaNoindexGenerator,
  hostnameResolver,
  path
} from 'Utils'
import config from 'Config'
import { PAGE_CONTEXT as p } from 'Constants'
const { canonicalAlts } = config

const PageHelmet = props => {
  const {
    match,
    pageName,
    description,
    titleFormat = {},
    htmlAttributes,
    storeName,
    location = {}
  } = props

  const articlesTest =
    config.name !== 'dm' && config.name !== 'dmc' && config.articlesEnabled
  const trendsTest =
    config.name !== 'octer' &&
    config.name !== 'dm' &&
    config.name !== 'dmc' &&
    config.trendsEnabled
  const productsTest = config.name !== 'dmc'

  let canonicalHref = `${hostnameResolver(config.name)}${location.pathname}`

  if (config.useCanonical) {
    const isArticle = pageName === 'article'
    const articleId = isArticle ? match.params.uuid : ''
    const articleSlug = isArticle ? match.params.slug : ''

    if (isArticle && canonicalAlts && canonicalAlts[articleId]) {
      const host = hostnameResolver(canonicalAlts[articleId].partner)
      const articlePath = path(p.ARTICLE, {
        uuid: canonicalAlts[articleId].id,
        slug: articleSlug
      })
      canonicalHref = `${host}${articlePath}`
    }
  }

  return (
    <Helmet htmlAttributes={htmlAttributes}>
      {metaNoindexGenerator(pageName, storeName)}
      <title>{metaTitleGenerator(pageName, titleFormat)}</title>
      <meta name="description" content={description} />
      <meta
        name="custom-app-data"
        data-cy-size={props.screenType}
        data-cy-products={productsTest}
        data-cy-articles={articlesTest}
        data-cy-trends={trendsTest}
      />
      {config.useCanonical && <link rel="canonical" href={canonicalHref} />}
    </Helmet>
  )
}

PageHelmet.propTypes = {
  match: PropTypes.object,
  description: PropTypes.any,
  htmlAttributes: PropTypes.any,
  location: PropTypes.object,
  pageName: PropTypes.any,
  screenType: PropTypes.any,
  storeName: PropTypes.any,
  titleFormat: PropTypes.object
}

const mapStateToProps = state => ({
  screenType: state.UI.screenType
})

export default withRouter(connect(mapStateToProps, null)(PageHelmet))
