import { ACTION_TYPES as types } from 'Constants'

export default (state = false, action = {}) => {
  switch (action.type) {
    case types.TOGGLE_LOADING:
      if (!state) {
        return true
      } else {
        return false
      }

    default:
      return state
  }
}
