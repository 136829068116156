import { css } from '@emotion/core'
import { smallOnly, mediumUp } from 'Utils'
import theme from 'Theme'
const {
  colors,
  mixins,
  components: { storeListFilter }
} = theme

export const plain = () => css`
  &.list {
    list-style-type: none;
    list-style: none;
    overflow: auto;
    max-height: 300px;
    padding-bottom: 40px;

    @media ${smallOnly} {
      max-height: calc(100vh - 140px);
    }
    @media ${mediumUp} {
      max-height: 300px;
    }
  }

  .list-item {
    a {
      padding: 8px;
      line-height: 16px;
      display: flex;
      width: 100%;
      align-items: center;
      position: relative;
      @media ${mediumUp} {
        &:hover {
          cursor: pointer;
          background-color: ${colors.mainNav.active};
          color: ${colors.mainNav.complementary.active};
        }
      }
    }

    @media ${smallOnly} {
      padding: 16px 8px;
      ${mixins.filterPanelListItem(theme)}
      border-bottom: 1px solid ${colors.mobileBorderLine};
    }

    @media ${mediumUp} {
      &:hover {
        color: ${storeListFilter.title.hoverColor};
      }
    }

    &.selected {
      a {
        background-color: black;
        color: white;
      }

      @media ${smallOnly} {
        background-color: black;
        color: white;
        a {
          display: block;
          width: 100%;
        }
      }
    }
  }

  .close-icon {
    ${mixins.closeIcon(theme)}
    position: absolute;
    right: 10px;
    top: calc(50% - 7px);

    @media ${smallOnly} {
      display: none;
    }
  }

  .list-item__label {
    text-transform: capitalize;
    height: 18px;
    line-height: 1.1;
  }

  .color-fill {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid whitesmoke;
    background-color: gainsboro;
    margin-right: 10px;
  }
`

export default plain
