import PropTypes from 'prop-types'
import React from 'react'
import { path, contentCardGALabel, sanitize, sendTrackingEvent, createContentCardGAProps } from 'Utils'
import config, { gaSettings } from 'Config'
import get from 'lodash/get'
import { LazyLoadImage, Link } from 'Blocks'

const ContentCard = props => {
  const imageWidth = get(config, 'articleCard.image.width', 600)
  const imageHeight = get(config, 'articleCard.image.height', '')
  const withExtraInfo = get(config, 'articleCard.withExtraInfo', false)
  const { showTaggedCategory, showSponsoredBy } = config.article
  let subText = ''
  const categoryName = get(props, 'content.theme', false)
  const isSponsored = get(props, 'content.sponsor', false)

  if (showSponsoredBy && isSponsored) {
    subText = `Sponsored`
  } else if (showTaggedCategory && categoryName) {
    subText = categoryName
  }

  const imageUrl = get(props, 'content.image.main', undefined)

  const {
    pageName = '',
    gaContext = '',
    className,
    activeClass,
    position
  } = props
  const { uuid, title, slug, type } = props.content
  const cardGASettings = gaSettings.article.ContentCard
  const labelProps = {
    ...{ uuid },
    ...{ type },
    ...{ position }
  }
  const label = contentCardGALabel(labelProps)
  const additionalProps = createContentCardGAProps(labelProps)
  const gaEvent = {
    category: cardGASettings.category.replace(
      '$pageName',
      gaContext || pageName
    ),
    action: cardGASettings.action,
    label: label.join(' - ')
  }

  return (
    <Link
      data-cy="content-card"
      className={`${className} ${activeClass} article-list-item`}
      to={path(type, { uuid, slug })}
      onClick={() => {
        sendTrackingEvent({ amplitude: {}, gaEvent, additionalProps})
      }}
    >
      <LazyLoadImage
        alt={title.long}
        image={imageUrl}
        className="image"
        width={imageWidth}
        height={imageHeight}
        crop="lfill"
      />
      <div className="article-info">
        <h2 className="article-panel-title">{sanitize(title.long)}</h2>
        {withExtraInfo && <h2 className="article-category-name">{subText}</h2>}
      </div>
      {props.children}
    </Link>
  )
}

ContentCard.propTypes = {
  activeClass: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.any,
  content: PropTypes.shape({
    uuid: PropTypes.any,
    title: PropTypes.shape({
      long: PropTypes.any
    }),
    slug: PropTypes.any,
    type: PropTypes.any
  }),
  gaContext: PropTypes.string,
  image: PropTypes.string,
  pageName: PropTypes.string,
  position: PropTypes.any
}

ContentCard.defaultProps = {
  activeClass: '',
  image: 'http://via.placehold.it/300x200'
}

export default ContentCard
