import config from 'Config'

export const path = (name, params = {}, queryString) => {
  let path = config.routes[name].path
  Object.entries(params).forEach(([key, value]) => {
    path = path.replace(`:${key}`, value)
  })
  if (queryString) {
    path += queryString
  }
  return path
}
