import ImageKit from 'imagekit-javascript'

const imagekitUrl = (image, options) => {
  options['default image'] =
    'https://ik.imagekit.io/sqhmihmlh/missing_image.jpg'

  var imagekit = new ImageKit({
    publicKey: 'public_lKudQo3dzkfMXMLBC6fsyFIK7YE=',
    urlEndpoint: 'https://ik.imagekit.io/sqhmihmlh/'
  })

  return imagekit.url({
    src: `https://ik.imagekit.io/sqhmihmlh/${image}`,
    transformation: [options]
  })
}

export default imagekitUrl
