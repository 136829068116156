export const spacing = {
  header: {
    small: {
      top: '50px'
    },
    large: {
      top: '50px'
    }
  },
  vertical: {
    small: '1rem',
    medium: '1.5rem',
    large: '2rem',
    larger: '3rem'
  }
}
export default spacing
