import React from 'react'
import { string, any } from 'prop-types'
import { skin } from 'Utils'
import skins from './skins'

const Grid = props => {
  const attributes = {
    className: props.className,
    itemScope: props.itemScope,
    itemProp: props.itemProp,
    itemType: props.itemType
  }

  return <div {...attributes}>{props.children}</div>
}

Grid.defaultProps = {
  itemProp: '',
  itemScope: '',
  itemType: 'http://schema.org/ItemList'
}

Grid.propTypes = {
  children: any,
  className: any,
  itemProp: string,
  itemScope: string,
  itemType: string
}

export default skin(Grid, skins)
