import React, { Component } from 'react'
import get from 'lodash/get'
import { capitalize } from 'Utils'
import config from 'Config'

export function gaReady() {
  let windowHasGA = false

  const isClient = typeof window !== 'undefined'
  if (isClient) {
    const ga = window.ga
    windowHasGA = window.ga && ga.loaded
  }

//   return isClient && !!windowHasGA
  return false
}

export function gtagReady() {
  let windowHasGTAG = false

  const isClient = typeof window !== 'undefined'
  if (isClient) {
    const gtag = window.gtag
    windowHasGTAG = gtag && gtag.name === 'gtag'
  }
  return isClient && !!windowHasGTAG
}

function withGAEvent(WrappedComponent, props) {
  class withGAEvent extends Component {
    render() {
      const { on, type, category, action, label, amplitude, additionalProps } = props

      const event = {
        hitType: type || 'event',
        eventCategory: category,
        eventAction: action,
        eventLabel: label
      }

      const isWindowAmplitude =
        typeof window !== 'undefined' && window.amplitude !== 'undefined'

      const send = () => {
        if (config.amplitude.enabled && isWindowAmplitude) {
          const eventType = get(amplitude, 'eventType', false)
          const eventProps = get(amplitude, 'eventProps', false)
          if (eventType && eventProps) {
            /* eslint-disable no-undef */
            window.amplitude.getInstance().logEvent(eventType, eventProps)
            /* eslint-enable no-undef */
          }
        }
        if (gaReady()) {
          /* eslint-disable no-undef */
          ga('send', event)
          ga(`${process.env.ANT_GA_CLIENT_TRACKER_NAME}.send`, event)
          /* eslint-enable no-undef */
        }

        if (gtagReady()) {
          // eslint-disable-next-line no-undef
          gtag(
            'event',
            action,
            {
              category,
              tracker: `${process.env.ANT_GA_CLIENT_TRACKER_NAME}.send`,
              ...additionalProps,
            }
          )
        }
      }

      const settings = {
        [`on${capitalize(on || 'click')}`]: send
      }

      if (config.gaEnabled === false) {
        return props => <WrappedComponent {...props} />
      } else {
        return <WrappedComponent {...this.props} {...settings} />
      }
    }
  }
  withGAEvent.displayName = 'withGAEvent'
  return withGAEvent
}

export default withGAEvent
