import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import Component from './component'

const GridContainer = props => {
  return <Component {...props}>{props.children}</Component>
}

const mapStateToProps = state => ({
  screenType: get(state, 'UI.screenType', '')
})

GridContainer.propTypes = {
  children: PropTypes.any
}

export default connect(mapStateToProps, null)(GridContainer)
