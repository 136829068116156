import PropTypes from 'prop-types'
import React from 'react'
import { skin } from 'Utils'
import skins from '../skins'
import { SCREEN_TYPE } from 'Constants'
import { Grid } from 'Blocks'

const cards = [1, 2, 3, 4]

const Loading = props => {
  const limit =
    props.screenType === SCREEN_TYPE.SMALL
      ? 1
      : props.screenType === SCREEN_TYPE.MEDIUM
      ? 2
      : 4
  return (
    <div className={`${props.className}`}>
      <Grid>
        {cards.slice(0, limit).map((card, index) => (
          <div key={`card-loading-${index}`} className="card-loading"></div>
        ))}
      </Grid>
    </div>
  )
}

Loading.propTypes = {
  className: PropTypes.any,
  screenType: PropTypes.any
}

export default skin(Loading, skins)
