import { ACTION_TYPES as types } from 'Constants'

const defaultState = {
  price: {
    min: 0,
    max: 0,
    set: false
  }
}

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.STORE_PRODUCTS_RESPONSE:
      return {
        ...state,
        products: {
          categories: action.payload.products.categories,
          aggregations: action.payload.products.aggregations,
          products: [action.payload.products.products[0]]
        }
      }

    case types.PUSH_PRICE_FILTERS:
      if (state.price.set) {
        return state
      }
      return {
        ...state,
        price: {
          set: false,
          ...action.payload
        }
      }

    case types.SET_PRICE_FILTERS:
      return {
        ...state,
        price: {
          ...state.price,
          set: action.payload
        }
      }

    default:
      return state
  }
}
