import { gql } from '@apollo/client'
import { smallContentFragment } from 'Fragments'

const GET_NAV_PANEL_CONTENTS = gql`
  query contentsMenu($pagination: Pagination!) {
    navPanelArticles: contents(
      arguments: { type: "article", state: "PUBLISHED" }
      pagination: $pagination
    ) {
      _cache
      aggregations {
        count
        themes {
          name
          count
        }
        celebrities {
          name
          count
        }
      }
      contents {
        ...smallContent
      }
      pagination {
        next
        pages
        previous
      }
    }

    navPanelTrends: contents(
      arguments: { type: "trend", state: "PUBLISHED" }
      pagination: $pagination
    ) {
      _cache
      aggregations {
        count
        themes {
          name
          count
        }
        celebrities {
          name
          count
        }
      }
      contents {
        ...smallContent
      }
      pagination {
        next
        pages
        previous
      }
    }

    navPanelInstalooks: contents(
      arguments: { type: "insta", state: "PUBLISHED" }
      pagination: $pagination
    ) {
      _cache
      aggregations {
        count
        themes {
          name
          count
        }
        celebrities {
          name
          count
        }
      }
      contents {
        ...smallContent
      }
      pagination {
        next
        pages
        previous
      }
    }
  }

  ${smallContentFragment}
`

export const navPanelContentsVariables = () => ({
  pagination: {
    page: 1,
    size: 3
  }
})

export default GET_NAV_PANEL_CONTENTS
