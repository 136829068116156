import { mediumUp } from '../../utils/responsive'

export const defaultButtonPadding = () => `
  padding: .5rem 3rem;
`

export const defaultButton = (theme = {}) => `
  text-transform: uppercase;
  font-family: ${theme.typography.headings.fontFamily};
  display: inline-block;
  padding: .5rem 3rem;
  vertical-align: middle;
  text-align: center;
  line-height: 1em;
  letter-spacing: ${theme.typography.headings.subHeadLetterSpacing};
  background-color: ${theme.colors.bodyBackground};
  border: 1px solid ${theme.colors.primary};
  color: ${theme.colors.primary};
  transition: background-color 100ms ease-in, color 100ms ease-in;
  @media ${mediumUp} {
    &:hover {
      text-decoration: none;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.bodyBackground};
    }
  }
`

export const seeFilteredButton = (theme = {}) => `
  margin: ${theme.spacing.vertical.medium} auto 0 auto;
  display: block;
  color: ${theme.colors.primary};
  font-family: Arial;
  background-color: ${theme.colors.white};
  font-size: ${theme.typography.sizes.small};
  line-height: 26px;
  border: 1px solid ${theme.colors.primary};
  &:hover {
    background-color: ${theme.colors.primary};
    border: 1px solid ${theme.colors.primary};
    color: ${theme.colors.white};
  }
  &:disabled {
    border: 1px solid ${theme.colors.button.disabled};
    color: ${theme.colors.button.disabled};
    cursor: default;
  }
`

export const mainContainerTopPadding = (theme = {}) => `
  padding-top:${theme.spacing.vertical.small};
  @media ${mediumUp} {
    padding-top: ${theme.spacing.vertical.large};
  }
}`

const mainContainerMaxWidth = (theme = {}) => `
  max-width: calc(100% - ${theme.spacing.base_3 * 2}px);
  margin: 0 auto;
  @media ${mediumUp} {
    max-width: ${theme.layout.maxWidth.page}px;
  }
  @media screen and (max-width: ${theme.layout.maxWidth.page}px) {
    max-width: 95%;
  }
`

export const customArticleButton = (theme = {}) => `
  font-family: ${theme.typography.headings.fontFamily};
  display: inline-block;
  padding: .5rem 3rem;
  vertical-align: middle;
  text-align: center;
  line-height: 1em;
  letter-spacing: ${theme.typography.headings.subHeadLetterSpacing};
  color: ${theme.components.articleItem.button.color};
  background-color: ${theme.components.articleItem.button.backgroundColor};
  border: 2px solid ${theme.components.articleItem.button.borderColor};
  transition: background-color 100ms ease-in, color 100ms ease-in, border-color 100ms ease-in;
  @media ${mediumUp} {
    &:hover {
      text-decoration: none;
      color: ${theme.components.articleItem.button.hover.color};
      background-color: ${theme.components.articleItem.button.hover.backgroundColor};
      border-color: ${theme.components.articleItem.button.hover.borderColor};
    }
  }
  &:visited {
    color: ${theme.components.articleItem.button.color};
    @media ${mediumUp} {
      &:hover {
        color: ${theme.components.articleItem.button.hover.color};
      }
    }
  }
`

export const imageCredit = `
  text-align: center;
  font-style: italic;
  font-size: 14px;
  color: #B9B8B8;
`

const closeIcon = (theme = {}) => `
  width: ${theme.components.icon.size.small};
  height: ${theme.components.icon.size.small};
  fill: white;
`

const cardBoxShadow = (theme = {}) => `
  box-shadow: 2px 2px 5px 2px ${theme.colors.boxShadow};
`

export const filterPanelListItem = (theme = {}) => `
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid ${theme.colors.borderGrey};
  position: relative;
  &.selected {
    svg {
      fill: white;
    }
  }
  svg[name="close"] {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const transparencyGradient = () => `
  -webkit-mask-image: -webkit-linear-gradient(
    bottom, 
    rgba(255,255,255,0.25) 0%, 
    rgba(255,255,255,0.5) 10%,
    rgba(255,255,255,1) 20%, 
    rgba(255,255,255,1) 100%);
  -webkit-mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
`

export const downArrow = (theme = {}) => {
  return `
    content: ' ';
    height: 0;
    width: 0;
    border: 3px solid transparent;
    border-top: 3px solid ${theme.elementColors.select.downArrow};
  `
}

const defaultVerticalSpacing = (theme = {}) => `
  margin-bottom: ${theme.spacing.vertical.small};
  @media ${mediumUp} {
    margin-bottom: ${theme.spacing.vertical.large};
  }
`

export const articleButton = () => `
  display: inline-block;
  padding: .5rem 1rem;
  color: #666;
  border: 2px solid #F2F2F2;
  @media ${mediumUp} {
    &:hover {
      color: white;
      background-color: black;
      border: 2px solid black;
    }
  }
`

export const loadingLight = (theme = {}) => {
  const {
    colors: {
      loading: {
        panel: { light }
      }
    }
  } = theme
  const { backgroundColor, animationColor } = light
  return `
  width: 100%;
  background: linear-gradient(270deg, ${backgroundColor}, ${backgroundColor}, ${animationColor}, ${backgroundColor}, ${backgroundColor}, ${backgroundColor});
  background-size: 1200% 1200%;
  -webkit-animation: Loading 6s ease infinite;
  -moz-animation: Loading 6s ease infinite;
  animation: Loading 6s ease infinite;

  @-webkit-keyframes Loading {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @-moz-keyframes Loading {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @keyframes Loading {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
`
}

export const loadingBlack = () => `
  width: 100%;
  height: 40px;
  background: linear-gradient(270deg, #000000, #000000, #e6e6e6, #000000, #000000, #000000);
  background-size: 1200% 1200%;

  -webkit-animation: AnimationName 4s ease infinite;
  -moz-animation: AnimationName 4s ease infinite;
  animation: AnimationName 4s ease infinite;

  @-webkit-keyframes AnimationName {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @-moz-keyframes AnimationName {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @keyframes AnimationName {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }

`
export const loadingBackground = (backgroundColor, waveColor) => `
  width: 100%;  
  background: linear-gradient(270deg, ${backgroundColor}, ${backgroundColor}, ${waveColor}, ${backgroundColor}, ${backgroundColor}, ${backgroundColor});
  background-size: 1200% 1200%;

  -webkit-animation: AnimationName 4s ease infinite;
  -moz-animation: AnimationName 4s ease infinite;
  animation: AnimationName 4s ease infinite;

  @-webkit-keyframes AnimationName {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @-moz-keyframes AnimationName {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @keyframes AnimationName {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }

`

const filterLinks = (theme = {}) => `
  display: block;
  line-height: 16px;
  padding: 8px;
  @media ${mediumUp} {
    &:hover {
      cursor: pointer;
      background-color: ${theme.colors.mainNav.active};
      color: ${theme.colors.mainNav.complementary.active};
    }
  }
`

export const fadeIn = () => `
  -webkit-animation: FadeIn 0.15s ease-in;
  -moz-animation: FadeIn 0.15s ease-in;
  animation: FadeIn 0.15s ease-in;

  @-webkit-keyframes FadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @-moz-keyframes FadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes FadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
`

const brandTypography = (theme = {}) => `
  font-weight: normal;
  text-transform: uppercase;
  font-size: ${theme.typography.sizes.small};
  letter-spacing: 1.3;
`

const subText = (theme = {}) => `
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: ${theme.typography.headings.letterSpacing};
`

const sectionHeading = (theme = {}) => `
  font-size: ${theme.typography.sizes.large};
  letter-spacing: ${theme.typography.headings.letterSpacing};
  line-height: 1em;
  font-weight: bold;
  color: ${theme.colors.primary};
  text-transform: uppercase;
  @media ${mediumUp} {
    font-size: ${theme.typography.sizes.larger};
  }
`

const ArticleSubheaderTextCustom = (theme = {}) => `
  font-size: 18px;
  font-family: ${theme.typography.body.fontFamily};
  font-weight: bold;
`

const ArticleSmallTextCustom = (theme = {}) => `
  font-size: 12px;
  font-family: ${theme.typography.body.fontFamily};
`

export const filterPanelHeader = (theme = {}) => `
  height: 65px;
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.complementary.primary};
`

export const navPanelListItem = (theme = {}) => `  
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
  padding: 16px 20px 16px 16px;
  border-bottom: 1px solid gainsboro;
  
  @media ${mediumUp} {
    padding: 8px;
    border-bottom: none;  
  }

  &:hover {
    cursor: pointer;
    background-color: ${theme.colors.mainNav.active};
    color: ${theme.colors.mainNav.complementary.active};
  }
`

export default {
  mainContainerMaxWidth,
  mainContainerTopPadding,
  filterPanelHeader,
  customArticleButton,
  sectionHeading,
  subText,
  ArticleSubheaderTextCustom,
  ArticleSmallTextCustom,
  brandTypography,
  articleButton,
  downArrow,
  defaultVerticalSpacing,
  defaultButton,
  defaultButtonPadding,
  loadingBackground,
  loadingLight,
  loadingBlack,
  fadeIn,
  imageCredit,
  transparencyGradient,
  filterPanelListItem,
  filterLinks,
  closeIcon,
  cardBoxShadow,
  navPanelListItem,
  seeFilteredButton
}
