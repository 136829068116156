import { mediumUp } from 'Utils'

export const maxPageWidth = '1350px'

export const innerMaxWidth = '1080px'

export const styledImage = (theme = {}) => `
  display: block;
  border: 1px solid ${theme.colors.active};
  border-radius: 4px;
  padding: 5px;
  background-clip: content-box;
  background-color: ${theme.colors.bodyBackground};
`

export const defaultHeadingFont = (theme = {}) => `
  font-family: ${theme.typography.headings.fontFamily};
  letter-spacing: ${theme.typography.headings.letterSpacing};
`

export const defaultVerticalSpacing = (theme = {}) => `
  margin-bottom: ${theme.spacing.vertical.small};
  @media ${mediumUp} {
    margin-bottom: ${theme.spacing.vertical.large};
  }
`

export const defaultButton = (theme = {}) => `
  ${defaultHeadingFont(theme)}
  font-weight: normal;
  line-height: 1.1em;
  font-size: ${theme.typography.sizes.large};
  border-radius: 4px;
  background-color: ${theme.colors.active};
  color: ${theme.colors.complementary.primary};
  display: inline-block;
  padding: .5rem 2rem;
  transition: background-color 150ms ease-in;
  &:hover {
    text-decoration: none;
    background-color: ${theme.colors.alt2};
    color: ${theme.colors.complementary.primary};
  }
  &:visited {
    color: ${theme.colors.complementary.primary};
  }
`

export default {
  maxPageWidth,
  innerMaxWidth,
  defaultHeadingFont,
  defaultVerticalSpacing,
  defaultButton,
  styledImage
}
