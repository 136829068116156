import { typography } from '../typography'
import { colors } from '../colors'

export const dropdown = {
  toggleFont: typography.body.fontFamily,
  toggleWeight: 'bold',
  toggleColor: colors.body,
  toggleSize: typography.baseFontSize
}
export default dropdown
