import PropTypes from 'prop-types'
import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_NAV_PANEL_CONTENTS, navPanelContentsVariables } from 'Queries'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Component from './component'
import { Loading } from './components'
import {
  toggleNavPanel,
  closeNavPanel,
  openNavPanel,
  setCurrentPath
} from 'Actions'
import { skin } from 'Utils'
import skins from './skins'

const mapStateToProps = (state, props) => ({
  isTouch: state.UI.isTouch,
  navPanel: state.UI.navPanel,
  activeTopLevelCategory: state.UI.mainMenu.activeTopLevelCategory,
  currentPath: state.UI.mainMenu.currentPath,
  activeContentType: state.UI.mainMenu.activeContentType,
  screenType: state.UI.screenType
})

const mapDispatchToProps = dispatch => ({
  setCurrentPath: bindActionCreators(setCurrentPath, dispatch),
  closeNavPanel: bindActionCreators(closeNavPanel, dispatch),
  openNavPanel: bindActionCreators(openNavPanel, dispatch),
  toggleNavPanel: bindActionCreators(toggleNavPanel, dispatch)
})

const ContentsMenu = props => {
  const { data, loading, error } = useQuery(GET_NAV_PANEL_CONTENTS, {
    variables: navPanelContentsVariables(),
    fetchPolicy: 'no-cache'
  })
  if (loading) return <Loading screenType={props.screenType} />
  if (error) return <p>ERROR</p>

  return <Component {...props} data={data} />
}

ContentsMenu.propTypes = {
  screenType: PropTypes.string
}

export default skin(
  connect(mapStateToProps, mapDispatchToProps)(ContentsMenu),
  skins
)
