import { css } from '@emotion/core'
import theme from 'Theme'
import { smallOnly, mediumUp } from 'Utils'

const { colors, spacing } = theme

export default () => css`
  @media ${smallOnly} {
    padding: 10px;
    height: 42px;
  }

  &.loading {
    display: flex;
    align-items: center;
  }

  .spinner {
    width: 50px;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  .spinner > div {
    width: 12px;
    height: 12px;
    background-color: ${colors.loading.color};

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  .items-found__label {
    display: block;
    color: ${theme.colors.secondary};
    text-align: center;
    @media ${mediumUp} {
      text-align: right;
      line-height: 1;
      margin-bottom: ${spacing.base_1}px;
    }
  }
`
