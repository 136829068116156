const { ANT_PROTOCOL, ANT_DOMAIN } = process.env

export const hostnameResolver = partner => {
  let partnerDomain = ''
  switch (partner) {
    case 'msn':
      partnerDomain = 'https://www.msn.com'
      break
    case 'ff':
      partnerDomain = 'https://www.femalefirst.co.uk'
      break
    case 'bw':
      partnerDomain = 'https://bluewater.co.uk'
      break
    case 'tl':
      partnerDomain = 'https://trinityleeds.com'
      break
    case 'bg':
      partnerDomain = 'https://buchanangalleries.co.uk'
      break
    case 'onc':
      partnerDomain = 'https://onenewchange.com'
      break
    case 'br':
      partnerDomain = 'https://www.bullring.co.uk'
      break
    case 'wq':
      partnerDomain = 'https://www.west-quay.co.uk'
      break
    case 'bc':
      partnerDomain = 'https://www.brentcross.co.uk'
      break
    case 'cc':
      partnerDomain = 'https://www.cabotcircus.com'
      break
    case 'cr':
      partnerDomain = 'https://closeronline.co.uk'
      break
    case 'hc':
      partnerDomain = 'https://www.highcrossleicester.com'
      break
    case 'sb':
      partnerDomain = 'https://www.shopsilverburn.com'
      break
    case 'to':
      partnerDomain = 'https://www.theoracle.com'
      break
    case 'us':
      partnerDomain = 'https://www.unionsquareaberdeen.com'
      break
    case 'vl':
      partnerDomain = 'https://www.victorialeeds.co.uk'
      break
    case 'dmc':
      partnerDomain = 'https://shopping.dailymail.co.uk'
      break
    case 'dm':
      partnerDomain = 'https://shopping.dailymail.co.uk'
      break
    case 'gr':
      partnerDomain = 'https://graziadaily.co.uk'
      break
    case 'gt':
      partnerDomain = 'https://www.gaytimes.co.uk/the-edit'
      break
    case 'octer':
      partnerDomain = 'https://octer.co.uk'
      break
    case 'pm':
      partnerDomain = 'https://www.port-magazine.com'
      break
    case 'tm':
      partnerDomain = 'https://shop.mirror.co.uk'
      break
    default:
      partnerDomain = `${ANT_PROTOCOL}:${ANT_DOMAIN}`
  }
  return partnerDomain
}
