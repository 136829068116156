import { css } from '@emotion/core'
import theme from 'Theme'

const { spacing, mixins, layout } = theme
const cardHeight = layout.navPanel.height - spacing.base_3 * 2
export default () => css`
  padding: ${spacing.base_3}px;

  .card-loading {
    height: ${cardHeight}px;
    ${mixins.loadingLight(theme)}
  }
`
