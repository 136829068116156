import { css } from '@emotion/core'

export default ({
  theme: {
    typography,
    components: { priceFilter }
  }
}) => {
  return css`
    .title {
      text-transform: uppercase;
      font-weight: 600;
      color: ${priceFilter.titleColor};
    }

    .dropdowns-wrapper {
      display: flex;
      justify-content: space-between;
    }

    .min-price-block,
    .max-price-block {
      width: 47.5%;
    }

    .dropdown {
      font-size: ${typography.baseFontSize};
      margin: 0;
      border: 1px solid ${priceFilter.borderColor};
      appearance: none;
      display: inline-block;
      padding: 0.3em;
      border-radius: 0;
      background: url('http://ik.imagekit.io/sqhmihmlh/octer/select-bg-compressor.png')
        center right no-repeat;
      background-size: cover;
      font-family: ${priceFilter.fontFamily};
      letter-spacing: ${typography.headings.letterSpacing};
      color: ${priceFilter.color};
    }
  `
}
