export const layout = {
  gutterSize: {
    small: 10,
    large: 20,
    fallback: 20
  },
  itemsPerRow: {
    altProduct: { small: 2, fallback: 3 },
    compare: { small: 2, fallback: 4 },
    default: { small: 1, medium: 2, fallback: 3 },
    productList: { small: 2, medium: 2, large: 3, fallback: 3 },
    stores: { small: 1, fallback: 2 }
  },
  maxWidth: {
    page: 1350,
    article: 740,
    card: 600
  }
}

export default layout
