import { css } from '@emotion/core'

export default ({ theme: { colors, typography } }) => css`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 1rem;

  .title {
    color: ${colors.text2};
  }

  a,
  button {
    appearance: none;
    color: ${colors.text2};
    cursor: pointer;
    padding: 0 0.5rem;

    &:visited,
    &:active {
      color: ${colors.text2};
    }

    &:hover,
    &.selected {
      color: ${colors.active};
    }

    &:first-of-type {
      border-right: 1px solid ${colors.text2};
    }
  }
`
