import { ACTION_TYPES, PAGE_CONTEXT as p } from 'Constants'
import { combineReducers } from 'redux'
import find from 'lodash/find'
import get from 'lodash/get'

const {
  JOURNEY_TRACK,
  TRACK_LINK,
  TRACK_LINK_DECREASE,
  LOG_CURRENT_PAGE
} = ACTION_TYPES

class Journey {
  constructor() {
    this.items = []
    this.payload = {}
  }

  setRouteName(routeName) {
    this.routeName = routeName
  }

  setItems(items) {
    this.items = items
  }

  newJourney() {
    this.items = []
  }

  removeItemByRouteName(routeName) {
    const newItems = this.items.filter(i => i.routeName !== routeName)
    this.items = newItems
  }

  sliceToSameRouteAndName() {
    const { name, routeName } = this.payload
    for (let index = 0; index < this.items.length; index++) {
      const i = this.items[index]
      if (routeName === i.routeName && name === i.name) {
        this.setItems(this.items.slice(0, index))
        return
      }
    }
  }

  sliceToSameRoute(args = { occurrence: 1 }) {
    const { occurrence } = args
    const { routeName } = this.payload
    let itemsFound = 0
    for (let index = 0; index < this.items.length; index++) {
      const i = this.items[index]
      if (routeName === i.routeName) {
        itemsFound++
        if (itemsFound === occurrence) {
          this.setItems(this.items.slice(0, index))
        }
      }
    }
  }

  generateItems(payload) {
    this.payload = payload
    this.sliceToSameRoute()
    this.items.push(payload)

    return this.items
  }
}

class ProductsJourney extends Journey {
  setItems(items) {
    this.items = items
  }

  isTopCategory() {
    const topCategories = get(this.payload, 'extra.topCategories', [])
    return Boolean(find(topCategories, { to: this.payload.to }))
  }

  isBrowse() {
    const name = get(this.payload, 'name', '')
    return name === 'Browse'
  }

  isSearch() {
    const subCategory = get(this.payload, 'params.subCategory', '')
    return subCategory === 'search'
  }

  removePreviousLeaf() {
    if (!get(this, 'payload.extra.subCategories.length', null)) {
      const isALeaf = item => {
        return item.extra.subCategories.length === 0
      }
      const previousIndex = this.items.length - 1
      const previousItem = this.items[previousIndex]

      if (
        previousItem &&
        get(previousItem, 'extra.subCategories', null) &&
        isALeaf(previousItem)
      ) {
        this.setItems(this.items.slice(0, previousIndex))
      }
    }
  }

  sliceToSameRouteAndId() {
    const {
      params: { uuid: id },
      routeName
    } = this.payload
    for (let index = 0; index < this.items.length; index++) {
      const i = this.items[index]
      if (routeName === i.routeName && id === i.params.uuid) {
        this.setItems(this.items.slice(0, index))
        return
      }
    }
  }

  resetJourney() {
    this.items.forEach(item => {
      if (
        item.routeName === p.ARTICLE ||
        item.routeName === p.ARTICLE_LIST ||
        item.routeName === p.TREND ||
        item.routeName === p.TREND_LIST ||
        item.routeName === p.INSTALOOK ||
        item.routeName === p.INSTALOOK_LIST
      ) {
        this.newJourney()
      }
    })
  }

  generateItems(payload) {
    this.payload = payload
    this.removeItemByRouteName(p.PRODUCT)
    this.removeItemByRouteName(p.STORES)
    this.removeItemByRouteName(p.COMPARE)
    this.sliceToSameRouteAndId()

    if (this.isTopCategory()) {
      this.sliceToSameRoute()
    }

    if (this.isBrowse()) {
      this.newJourney()
    }

    if (this.isSearch()) {
      this.newJourney()
    }

    this.resetJourney()
    this.removePreviousLeaf()

    this.items.push(payload)
    return this.items
  }
}

class StoreProductsJourney extends ProductsJourney {
  generateItems(payload) {
    this.payload = payload
    this.removeItemByRouteName(p.PRODUCT)
    this.removeItemByRouteName(p.COMPARE)
    const hasStorePage = get(payload, 'hasStorePage')
    this.sliceToSameRouteAndId()

    if (this.isTopCategory()) {
      const occurrence = hasStorePage ? 1 : 2
      this.sliceToSameRoute({ occurrence })
    }

    this.removePreviousLeaf()

    this.items.push(payload)
    return this.items
  }
}

class StoresJourney extends Journey {
  generateItems(payload) {
    this.payload = payload
    this.newJourney()

    this.items.push(payload)

    return this.items
  }
}

const journeyTrack = (
  state = {
    items: []
  },
  action = {}
) => {
  let items = []

  const myStoreJourney = new StoreProductsJourney()
  const myStoresJourney = new StoresJourney()
  const myProductsJourney = new ProductsJourney()
  const myJourney = new Journey()

  switch (action.type) {
    case JOURNEY_TRACK: {
      switch (action.payload.routeName) {
        case p.HOME: {
          items = []
          break
        }

        case 'store-products': {
          myStoreJourney.setItems([...state.items])
          items = myStoreJourney.generateItems(action.payload)
          break
        }

        case p.STORES: {
          myStoresJourney.setItems([...state.items])
          items = myStoresJourney.generateItems(action.payload)
          break
        }

        case p.PRODUCT_LIST: {
          myProductsJourney.setItems([...state.items])
          items = myProductsJourney.generateItems(action.payload)
          break
        }

        default: {
          myJourney.setItems([...state.items])
          items = myJourney.generateItems(action.payload)
          break
        }
      }

      return {
        ...state,
        items
      }
    }

    default:
      return state
  }
}

const trackLink = (
  state = {
    refs: []
  },
  action = {}
) => {
  switch (action.type) {
    case TRACK_LINK: {
      const refs = [...state.refs]
      const currentRefIndex = refs.findIndex(
        el => el.key === action.payload.key
      )

      const trackRef = {
        key: action.payload.key,
        value: action.payload.value,
        depth: action.payload.depth + 1
      }

      if (currentRefIndex === -1) {
        refs.push(trackRef)
      } else {
        refs[currentRefIndex] = trackRef
      }

      return {
        ...state,
        refs
      }
    }

    case TRACK_LINK_DECREASE: {
      const newUrls = [...state.refs]

      newUrls.forEach((el, i) => {
        el.depth--
      })

      return {
        ...state,
        refs: newUrls.filter(el => el.depth > 0)
      }
    }

    default:
      return state
  }
}

const current = (state = { uuid: '' }, action = {}) => {
  if (action.type === LOG_CURRENT_PAGE) {
    return {
      ...state,
      uuid: action.payload.uuid
    }
  }
  return state
}

export default combineReducers({
  trackLink,
  current,
  track: journeyTrack
})
