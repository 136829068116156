import { connect } from 'react-redux'
import Component from './component'
import { bindActionCreators } from 'redux'
import { setCurrentPath, setCurrentCategory, closeNavPanel } from 'Actions'

const mapStateToProps = state => ({
  screenType: state.UI.screenType,
  activeTopLevelCategory: state.UI.mainMenu.activeTopLevelCategory
})

const mapDispatchToProps = dispatch => ({
  setCurrentPath: bindActionCreators(setCurrentPath, dispatch),
  setCurrentCategory: bindActionCreators(setCurrentCategory, dispatch),
  closeNavPanel: bindActionCreators(closeNavPanel, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
