import { colors } from '../colors'
import { typography } from '../typography'

export default {
  title: {
    color: colors.complementary.secondary
  },
  clear: {
    color: colors.text2,
    hover: {
      color: colors.complementary.secondary
    }
  },
  selectedItems: {
    color: colors.text2,
    hover: {
      color: colors.complementary.secondary
    }
  },
  scrollbar: {
    width: '8px',
    thumb: {
      color: colors.primary
    },
    track: {
      color: colors.tertiary
    }
  },
  font: {
    family: typography.body.fontFamily
  }
}
