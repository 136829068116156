import React from 'react'
import { skin } from 'Utils'
import skins from './skins'
import { string, bool, object } from 'prop-types'
import { Link } from 'Blocks'

const LabeledCheckbox = ({
  children = '',
  to = '',
  label = '',
  isSelected = false,
  isDisabled = false,
  className = '',
  style = {}
}) => {
  const checkbox = (
    <span className="checkbox-wrapper">
      {isSelected && <span className="checkbox" />}
    </span>
  )
  const labelText = <span className="checkbox-label">{label}</span>

  let component = (
    <Link {...{ to, className, style }}>
      {checkbox}
      {labelText}
      {children}
    </Link>
  )

  if (isDisabled) {
    component = (
      <span className={`disabled ${className}`} {...{ style }}>
        {checkbox}
        {labelText}
      </span>
    )
  }

  return component
}

LabeledCheckbox.displayName = 'LabeledCheckbox'

LabeledCheckbox.propTypes = {
  children: string,
  className: string,
  isDisabled: bool,
  isSelected: bool,
  label: string,
  style: object,
  to: string
}

export default skin(LabeledCheckbox, skins)
