import panelCard from './panelCard'
import priceFilter from './priceFilter'
import productCard from './productCard'
import storeListFilter from './storeListFilter'
import title from './title'
import categoryFilter from './categoryFilter'
import menu from './menu'
import breadcrumbs from './breadcrumbs'
import pagination from './pagination'
import icon from './icon'
import offCanvas from './offCanvas'
import adminPageToolbar from './adminPageToolbar'
import adminToolbar from './adminToolbar'
import productPrice from './productPrice'
import filterPanels from './filterPanels'
import productOverview from './productOverview'

export default {
  panelCard,
  priceFilter,
  productCard,
  storeListFilter,
  title,
  icon,
  offCanvas,
  categoryFilter,
  menu,
  breadcrumbs,
  pagination,
  adminToolbar,
  adminPageToolbar,
  productPrice,
  filterPanels,
  productOverview
}
