import { ACTION_TYPES as types } from 'Constants'

export default (state = '', action = {}) => {
  switch (action.type) {
    case types.OPEN_NAV_PANEL:
      return action.payload

    case types.CLOSE_NAV_PANEL:
      return ''

    case types.TOGGLE_NAV_PANEL:
      if (!state) {
        return action.payload
      } else {
        return ''
      }

    default:
      return state
  }
}
