import PropTypes from 'prop-types'
import React, { Component } from 'react'
import config, { gaSettings } from 'Config'
import get from 'lodash/get'
import { PAGE_CONTEXT as p } from 'Constants'
import { Link } from 'Blocks'
import { path, sendTrackingEvent } from 'Utils'

const { menu } = gaSettings

class CategoryPanels extends Component {
  static propTypes = {
    className: PropTypes.any,
    closeNavPanel: PropTypes.func,
    setCurrentCategory: PropTypes.func,
    setCurrentPath: PropTypes.func
  }

  handlePanelClick = uuid => {
    let pathsArr = []
    this.getPathToCategory(uuid, pathsArr)
    if (!this.isLeaf(uuid)) {
      pathsArr.push(uuid)
    }
    this.props.setCurrentPath(pathsArr)
  }

  getPathToCategory = (uuid, pathsArr) => {
    const parent = this.getParentCategory(uuid)
    if (parent) {
      pathsArr.unshift(parent)
      this.getPathToCategory(parent, pathsArr)
    } else {
      return false
    }
  }

  getParentCategory = uuid => {
    const categoryTree = get(this.props, 'categoryTree', [])
    for (var i = 0; i < categoryTree.length; i++) {
      if (categoryTree[i].children.length > 0) {
        if (categoryTree[i].children.includes(uuid)) {
          return categoryTree[i].uuid
        }
      }
    }
  }

  isLeaf = uuid => {
    const category = this.getCategoryFromUuid(uuid)
    return category ? category.children.length === 0 : null
  }

  getCategoryFromUuid = uuid => {
    const categoryTree = get(this.props, 'categoryTree', [])
    const categoryFromTree = categoryTree.filter(
      category => category.uuid === uuid
    )
    return categoryFromTree.length > 0 ? categoryFromTree[0] : null
  }

  render() {
    const activeTopLevelCategory = get(this.props, 'activeTopLevelCategory', {})
    const panelData = config.navPanelCategoryData[activeTopLevelCategory.uuid]
    let items = get(panelData, 'children', [])

    return (
      <div className={`category-panels ${this.props.className}`}>
        {items.slice(0, 3).map((panel, index) => {
          const categoryLink = path(p.PRODUCT_LIST, {
            uuid: panel.uuid,
            subCategory: panel.slug
          })
          const gaEvent = {
            category: menu.category,
            action: menu.action,
            label: `linkName=${panel.name} - destinationUrl=${categoryLink} - imagePanel=${index}`
          }
          const additionalProps = {
            linkName: panel.name,
            destinationUrl: categoryLink,
          }
          return (
            <Link
              onClick={() => {
                sendTrackingEvent({ amplitude: {}, gaEvent, additionalProps })
                this.handlePanelClick(panel.uuid)
                this.props.setCurrentCategory(panel.uuid)
                this.props.closeNavPanel()
              }}
              key={`panel-${index}`}
              className="category-panel"
              to={categoryLink}
            >
              <div
                className="category-panel__img"
                style={{
                  backgroundImage: `url('${panel.image}')`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover'
                }}
              ></div>

              <h2 className="category-panel__title">Shop {panel.name}</h2>
            </Link>
          )
        })}
      </div>
    )
  }
}

export default CategoryPanels
