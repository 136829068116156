import { colors } from './colors'

export const elements = {
  icons: {
    height: '16px',
    width: '16px'
  },

  input: {
    borderRadius: 0
  },

  scrollbar: {
    width: '8px',
    thumb: {
      color: colors.primary
    },
    track: {
      color: colors.tertiary
    }
  }
}
