import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Component from './component'
import { UrlBuilder } from 'Utils'

const ColorFilterContainer = props => {
  const { queryString } = props
  const s8colors = {
    multi: `linear-gradient(0deg, rgba(48,35,174,1) 0%, rgba(83,160,253,1) 40%, rgba(180,236,81,1) 100%)`,
    beige: '#FEF0D9',
    blue: '#4A90E2',
    brown: '#8B572A',
    gold: `linear-gradient(180deg, rgba(255,237,179,1) 0%, rgba(202,137,30,1) 100%)`,
    green: '#569C0A',
    grey: '#9B9B9B',
    navy: '#103F76',
    orange: '#FF9544',
    pink: '#EF8BA3',
    purple: '#B033DA',
    red: '#C40820'
  }

  const dataColors = get(
    props.data,
    'products.categories.current.filters[0].values',
    []
  )

  const colorValues = dataColors.map(item => item.name).sort()

  const colors = props =>
    colorValues.map(color => {
      const isSelected = !!queryString.match(new RegExp(`\\bcolor=${color}\\b`))
      const builder = new UrlBuilder(queryString)
      const s8color = s8colors[color] || color
      const to = isSelected
        ? builder.removeColor(color).setPage(1).getQueryString()
        : builder.addColor(color).setPage(1).getQueryString()

      return {
        s8color,
        color,
        isSelected,
        to
      }
    })

  return <Component items={colors(props)} queryString={queryString} />
}

ColorFilterContainer.propTypes = {
  data: PropTypes.any,
  queryString: PropTypes.string
}

export default ColorFilterContainer
