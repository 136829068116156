import { connect } from 'react-redux'
import get from 'lodash/get'
import component from './component'
import { skin, UrlBuilder } from 'Utils'
import skins from './skins'

const mapStateToProps = (state, props) => {
  const {
    SORT_DIRECTION_ASC: asc,
    SORT_DIRECTION_DESC: desc,
    SORT_DIRECTION_DEFAULT: def,
    SORT_ON_PRICE: price
  } = UrlBuilder

  const queryString = get(
    props,
    'location.search',
    get(state, 'UI.location.search', '')
  )
  const screenType = state.UI.screenType

  const getLink = linkType => {
    const builder = new UrlBuilder(queryString)
    const direction = builder.getSortDirection()

    if (direction === linkType) {
      builder.clearSort()
    } else {
      builder
        .setSortOn(price)
        .setSortDirection(direction === linkType ? def : linkType)
    }

    return builder.getQueryString()
  }

  const currentDirection = new UrlBuilder(queryString).getSortDirection()
  const isLowSelected = currentDirection === asc
  const isHighSelected = currentDirection === desc
  const lowTo = getLink(asc)
  const highTo = getLink(desc)

  return {
    lowLabel: 'Low',
    highLabel: 'High',
    isLowSelected,
    isHighSelected,
    lowTo,
    highTo,
    screenType
  }
}

const mappedComponent = connect(mapStateToProps, null)(component)

export default skin(mappedComponent, skins)
