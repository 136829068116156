import {
  array,
  string,
  object,
  number,
  arrayOf,
  bool,
  shape,
  any
} from 'prop-types'

export const routerPropTypes = {
  match: object,
  location: object,
  history: object,
  params: object
}

export const productPropTypes = {
  affiliateUrl: string,
  categories: arrayOf(object),
  brand: string,
  department: string,
  uuid: string,
  title: string,
  slug: string,
  image: string,
  description: string,
  comparisons: array,
  flags: shape({
    isStocked: bool,
    isDiscounted: bool,
    isFeatured: bool
  }),
  prices: shape({
    list: number,
    raw: number,
    rrp: number,
    currency: shape({
      symbol: string,
      code: string
    })
  }),
  store: shape({
    name: string
  })
}

export const contentPropTypes = {
  author: shape({
    name: string
  }),
  categories: array,
  celebrity: string,
  created: string,
  flags: shape({
    isFeatured: bool,
    isFromRetailer: bool,
    isHero: bool
  }),
  image: shape({
    cropped: string,
    hero: string,
    main: string
  }),
  layout: any,
  slug: string,
  sponsor: string,
  theme: string,
  type: string,
  uuid: string,
  title: shape({
    long: string,
    short: string
  })
}

// export const layoutPropTypes = {

// }
