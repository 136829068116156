import React from 'react'
import { string, bool } from 'prop-types'
import { Link } from 'Blocks'
import config from 'Config'

const Sorting = props => {
  const {
    lowTo,
    highTo,
    isLowSelected,
    isHighSelected,
    className,
    screenType
  } = props

  return (
    <div {...{ className }}>
      {screenType !== 'small' && (
        <span className="title">{config.sorting.productListTitle}</span>
      )}
      <div className="links">
        <Link className={isLowSelected ? 'selected' : ''} to={lowTo}>
          {config.sorting.lowLabel}
        </Link>
        <Link className={isHighSelected ? 'selected' : ''} to={highTo}>
          {config.sorting.highLabel}
        </Link>
      </div>
    </div>
  )
}

Sorting.defaultProps = {
  lowTo: '',
  highTo: '',
  isLowSelected: false,
  isHighSelected: false,
  className: ''
}

Sorting.propTypes = {
  className: string,
  highTo: string,
  isHighSelected: bool,
  isLowSelected: bool,
  lowTo: string,
  screenType: string
}

export default Sorting
