import React from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Title } from 'Blocks'
import CustomSelect from '../components/CustomSelect'
import { setPrice, SendGA } from '../utils'
import { MIN_PRICE_LIST, MAX_PRICE_LIST } from 'Constants'

const PriceFilter = props => {
  const { className, style, queryString, data, history } = props

  const bigger = (a, b) => (a > b ? a : b)
  const smaller = (a, b) => (a < b ? a : b)
  const maxPriceMatcher = queryString.match(new RegExp(`max=(\\d+)`))
  const minPriceMatcher = queryString.match(new RegExp(`min=(\\d+)`))
  const maxPrice = maxPriceMatcher ? maxPriceMatcher[1] : ''
  const minPrice = minPriceMatcher ? minPriceMatcher[1] : ''
  const dataMaxPrice = get(data, 'products.aggregations.prices.max', 100000)
  const dataMinPrice = get(data, 'products.aggregations.prices.min', 0)
  const maxLimit = smaller(dataMaxPrice, maxPrice)
  const minLimit = bigger(dataMinPrice, minPrice)

  const priceHandler = (min, e) => {
    setPrice(min, history, queryString, e.target.value)
    const isClient = typeof window !== 'undefined'
    if (isClient) {
      const eventLabel = `minPrice=${
        min ? e.target.value : minPrice
      } - maxPrice=${min ? maxPrice : e.target.value}`
      const additionalProps = {
        minPrice: parseInt(min ? e.target.value : minPrice),
        maxPrice: parseInt(min ? maxPrice : e.target.value),
      }
      SendGA(eventLabel, additionalProps)
    }
  }

  const minPriceHandler = e => priceHandler(true, e)
  const maxPriceHandler = e => priceHandler(false, e)

  const minimumFilter = value => {
    if (parseInt(value, 10) === minPrice) {
      return true
    }
    if (value === '') {
      return true
    }
    if (maxPrice === '') {
      if (parseInt(value, 10) < dataMaxPrice) {
        return true
      }
      return false
    }
    if (parseInt(value, 10) < smaller(maxLimit, maxPrice)) {
      return true
    }
    return false
  }

  const maximumFilter = value => {
    if (parseInt(value, 10) === maxPrice) {
      return true
    }
    if (value === '') {
      return true
    }
    if (minPrice === '') {
      if (parseInt(value, 10) > parseInt(dataMinPrice)) {
        return true
      }
      return false
    }
    if (parseInt(value, 10) > bigger(minLimit, minPrice)) {
      return true
    }
    return false
  }

  return (
    <div {...{ style }} className={`price-filter ${className}`}>
      {props.titleEnabled && <Title>Price</Title>}
      <div className={'dropdowns-wrapper'}>
        <CustomSelect
          className={'min-price-block'}
          currentValue={minPrice}
          onChange={minPriceHandler}
        >
          {MIN_PRICE_LIST.filter(({ key, label, value }) =>
            minimumFilter(value)
          ).map(({ key, label, value }) => (
            <option key={`min-${key}`} value={value}>
              {' '}
              {label}{' '}
            </option>
          ))}
        </CustomSelect>
        <CustomSelect
          className={'max-price-block'}
          currentValue={maxPrice}
          onChange={maxPriceHandler}
        >
          {MAX_PRICE_LIST.filter(({ key, label, value }) =>
            maximumFilter(value)
          ).map(({ key, label, value }) => (
            <option key={`max-${key}`} value={value}>
              {' '}
              {label}{' '}
            </option>
          ))}
        </CustomSelect>
      </div>
    </div>
  )
}

PriceFilter.displayName = 'PriceFilter'

PriceFilter.propTypes = {
  className: PropTypes.any,
  data: PropTypes.any,
  history: PropTypes.any,
  queryString: PropTypes.string,
  style: PropTypes.any,
  titleEnabled: PropTypes.bool
}

PriceFilter.defaultProps = {
  titleEnabled: true
}

export default withRouter(PriceFilter)
